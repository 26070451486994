import { ref } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { SkillCategory } from "@/types/types";

export function useAdminCategoryTable() {
  const category = ref<SkillCategory>({
    id: 0,
    name: "",
    createdTimestamp: new Date(),
    modifiedTimestamp: new Date(),
  });

  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  function clearFilters() {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      categoryName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    };
  }

  return {
    category,
    filters,
    clearFilters,
  };
}
