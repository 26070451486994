import { ref } from "vue";
import eventDataAccess from "@/api/eventDataAccess";
import { Event } from "@/types/event";
import { useToastService } from "@/utils/ToastService";
import { DataResponse } from "@/types/types";

export const useEvents = () => {
    const selEvents = ref<Event[]>([]);
    const events = ref<Event[]>([]);
    const toast = useToastService();
    const eventLoading = ref(false);

    async function getEvents() {
        eventLoading.value = true;

        let response: DataResponse<Event[]> | undefined;
        if(window.location.href.includes('accessToken')) {
            const urlParams = new URLSearchParams(window.location.search);
            let token = urlParams.get("accessToken");

            if(token == null)
            {
                token = "";
            }

            response = await eventDataAccess.getEventsWithAccessToken(token);
        } else {
            response = await eventDataAccess.getEvents();
        }

        if(response) {
            events.value = response.data;
        } else {
            toast.addErrorToast('Failed to Retrieve Events', '');
        }

        eventLoading.value = false;
    }

    async function getSelectedEvents() {
        eventLoading.value = true;

        let response: DataResponse<Event[]> | undefined;
        if(window.location.href.includes('accessToken')) {
            const urlParams = new URLSearchParams(window.location.search);
            let token = urlParams.get("accessToken");

            if(token == null)
            {
                token = "";
            }

            response = await eventDataAccess.getEventsWithAccessToken(token);
        } else {
            response = await eventDataAccess.getEvents();
        }

        if(response) {
            selEvents.value = response.data;
        } else {
            toast.addErrorToast('Failed to Retrieve Event', '');
        }

        eventLoading.value = false;
    }

    async function createEvent(event: FormData) {
        try {
            eventLoading.value = true;
            
            const response = await eventDataAccess.createEvent(event);

            if (response) {
                toast.addSuccessToast('Event uploaded successfully', '');
                await getEvents();
            } else {
                toast.addErrorToast('Failed to upload event', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to upload event', '');
        } finally {
            eventLoading.value = false;
        }
    }

    async function deleteEvent(eventId: number) {
        try {
            eventLoading.value = true;
            const response = await eventDataAccess.deleteEvent(eventId);
            if (response) {
                toast.addSuccessToast('Event deleted successfully', '');
                await getEvents();
            } else {
                toast.addErrorToast('Failed to delete event', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to delete event', '');
        } finally {
            eventLoading.value = false;
        }
    }

    async function selectEvent(eventId: number) {
        try {
            eventLoading.value = true;
            const response = await eventDataAccess.selectEvent(eventId);
            if (response) {
                toast.addSuccessToast('Event updated successfully', '');
                await getEvents();
            } else {
                toast.addErrorToast('Failed to update event', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to update event', '');
        } finally {
            eventLoading.value = false;
        }
    }

    async function deselectEvent(eventId: number) {
        try {
            eventLoading.value = true;
            const response = await eventDataAccess.deselectEvent(eventId);
            if (response) {
                toast.addSuccessToast('Event updated successfully', '');
                await getEvents();
            } else {
                toast.addErrorToast('Failed to update event', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to update event', '');
        } finally {
            eventLoading.value = false;
        }
    }

    init();
    async function init() {
        getEvents();
        getSelectedEvents();
    }
  
    return {
        events,
        selEvents,
        eventLoading,
        getEvents,
        getSelectedEvents,
        createEvent,
        deleteEvent,
        selectEvent,
        deselectEvent
    };
}