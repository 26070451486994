import api from "@/api/api";
import { controlPanelAccessToken, controlPanelAccessTokenUploadDTO } from "@/types/controlPanelAccessToken";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";

class controlPanelAccessTokensDataAccess {
  async createToken(tokenDescription: string): Promise<DataResponse<controlPanelAccessTokenUploadDTO> | undefined> {
    try {
      const response = await api.post("ControlPanelAccessTokens/Create", {
        description: tokenDescription,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getTokens(): Promise<DataResponse<controlPanelAccessToken[]> | undefined>{
    try {
      const response = await api.get("ControlPanelAccessTokens");

      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteToken(id: number): Promise<DataResponse<null> | undefined>{
    try {
      const response = await api.delete(`ControlPanelAccessTokens/${id}`);

      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }
}

const controlPanelDataAccess = new controlPanelAccessTokensDataAccess()
export default controlPanelDataAccess;