import { ref, watch } from "vue";
import { Engineer, Skill } from "@/types/types";
import userDataAccess from "@/api/userDataAccess";
import skillDataAccess from "@/api/skillDataAccess";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export function useSearchEngineers() {
  const users = ref<Engineer[]>([]);
  const loading = ref<boolean>(true);
  const skills = ref<Skill[]>([]);
  const filteredUsers = ref<Engineer[]>([]);
  const selectedSkills = ref<Skill[]>([]);
  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    lastName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  async function populateUsers() {
    const response = await userDataAccess.getEngineers();
    if (response != undefined) {
      response.data.forEach((user: Engineer) => users.value.push(user));
    }
    filteredUsers.value = users.value;
  }

  async function populateSkills() {
    const response = await skillDataAccess.getSkills();
    if (response != undefined) {
      skills.value = response.data;
    }
  }

  watch(
    () => [...selectedSkills.value],
    () => {
      if (selectedSkills.value.length === 0) {
        filteredUsers.value = users.value;
        return;
      }

      filteredUsers.value = users.value.filter((user) => {
        user.score = 0;
        let hasSkill = false;
        for (const skill of selectedSkills.value) {
          hasSkill = false;
          for (const s of user.skillLevels) {
            if (s.skill.name == skill.name) {
              user.score += s.level;
              hasSkill = true;
            }
          }
        }
        return hasSkill;
      });

      filteredUsers.value = filteredUsers.value.sort((a, b) =>
        a.score < b.score ? 1 : -1
      );
    },
    {
      deep: true,
    }
  );

  async function initialize() {
    await populateUsers();
    await populateSkills();
    loading.value = false;
  }

  initialize();

  return {
    filteredUsers,
    filters,
    skills,
    selectedSkills,
    loading,
  };
}
