import seatingChartDataAccess from "@/api/SeatingChartDataAccess";
import { useToastService } from "@/utils/ToastService";
import { ref } from "vue";
import { Location } from "@/types/seatingChart";

export function useLocations() {
  const locations = ref<Location[]>([]);
  const defaultLocation: Location = {
    id: 0,
    name: "",
  };
  const toast = useToastService();
  const locationsLoading = ref<Boolean>(false);

  init();
  async function init() {
    await populateLocations();
  }

  async function populateLocations() {
    locationsLoading.value = true;
    const summary = "Couldn't Get Locations";
    let detail = "Something went wrong.";
    const response = await seatingChartDataAccess.GetLocations();

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        locations.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
    locationsLoading.value = false;
  }

  return {
    locations,
    defaultLocation,
    locationsLoading,
    populateLocations,
  };
}
