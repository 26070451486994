import { ref } from "vue";
import { User, Position } from "@/types/types";
import userDataAccess from "@/api/userDataAccess";
import { useToastService } from "@/utils/ToastService";
import { useStore } from "@/store";

export function useManageUsersCRUD(userID: number) {
  const user = ref<User>({
    id: 0,
    graphId: "",
    firstName: "",
    lastName: "",
    email: "",
    positions: [],
    roles: [],
    skillLevels: [],
    active: true,
    deletedAt: undefined
  });
  const loading = ref(true);
  const positions = ref<Position[]>([]);
  const newPositions = ref<Position[]>();
  const toast = useToastService();
  const store = useStore();

  async function getUser(userID: number) {
    const response = await userDataAccess.getUserInfoAdmin(userID);
    if (response != undefined) {
      user.value = response.data;
      newPositions.value = user.value.positions;
    }
  }

  async function getPositionNames() {
    const response = await userDataAccess.getPositions();
    positions.value = [];
    if (response != undefined) {
      response.data.forEach((position: Position) => {
        positions.value.push(position);
      });
    }
    loading.value = false;
  }

  async function updateUser() {
    let summary = "User Could Not Be Updated";
    let detail = "Please select at least one position.";

    if (newPositions.value == undefined || newPositions.value.length <= 0) {
      toast.addErrorToast(summary, detail);
      return;
    }

    Promise.all([
      userDataAccess.updateUser(user.value),
      userDataAccess.setUserPositions(newPositions.value),
    ]).then((responses) => {
      if (!responses[0] || !responses[1]) {
        toast.addErrorToast(summary, detail);
      } else {
        if (responses[0].success && responses[1].success) {
          getUser(userID);
          store.dispatch("loadUserInfo");

          const name = `${user.value.firstName} ${user.value.lastName}`;
          summary = "User Updated";
          detail = `${name}'s information has been saved.`;
          toast.addSuccessToast(summary, detail);
        } else {
          detail = responses[0].message + " " + responses[1].message;
          toast.addErrorToast(summary, detail);
        }
      }
    });
  }

  getUser(userID);
  getPositionNames();
  return {
    user,
    newPositions,
    positions,
    updateUser,
    loading,
  };
}
