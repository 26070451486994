import seatingChartDataAccess from "@/api/SeatingChartDataAccess";
import {
  Floorplan,
  SeatingChart,
  UploadSeatingChartDTO,
} from "@/types/seatingChart";
import { ref } from "vue";
import { useToastService } from "@/utils/ToastService";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { Comment } from "@/types/comment";

export function useSeatingCharts() {
  const showCreateSeatingChart = ref<boolean>(false);
  const showCommentsPopUp = ref<boolean>(false);
  const seatingCharts = ref<SeatingChart[]>([]);
  const comments = ref<Comment[]>([]);
  const loading = ref<boolean>(false);
  const commentsLoading = ref<boolean>(false);
  const toast = useToastService();
  const showAddFloorplanToChart = ref<boolean>(false);
  const showRemoveFloorplanFromChart = ref<boolean>(false);
  const showCreateUserPopUp = ref<boolean>(false);
  const showRenameChartPopUp = ref<boolean>(false);
  const publishedSeatingChart = ref<SeatingChart>({
    id: 0,
    name: "",
    isPublished: false,
    userFloorplanRectangleLinks: [],
    floorplans: [],
  });

  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    seatingChartName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  init();
  async function init() {
    await getSeatingCharts();
    getPublishedSeatingChart();
    loading.value = false;
  }

  function toggleShowAddFloorplanToChartPopUp() {
    showAddFloorplanToChart.value = !showAddFloorplanToChart.value;
  }

  function toggleRemoveFloorplanPopup() {
    showRemoveFloorplanFromChart.value = !showRemoveFloorplanFromChart.value;
  }

  function toggleShowCreateUserPopUp() {
    showCreateUserPopUp.value = !showCreateUserPopUp.value;
  }

  function toggleShowRenameChartPopUp() {
    showRenameChartPopUp.value = !showRenameChartPopUp.value;
  }

  function toggleShowCommentsPopUp() {
    showCommentsPopUp.value = !showCommentsPopUp.value;
  }

  function renameSeatingChart(seatingChart: SeatingChart, name: string) {
    seatingChart.name = name;
  }

  async function deleteSeatingChart(seatingChart: SeatingChart) {
    let summary = "Couldn't Delete Seating Chart";
    let detail = "Something went wrong.";

    const response = await seatingChartDataAccess.deleteSeatingChart(
      seatingChart.id
    );
    if (!response) {
      toast.addErrorToast(summary, detail);
      return false;
    } else {
      if (response.success) {
        summary = "Seating Chart Deleted";
        detail = `${seatingChart.name} has been deleted.`;
        toast.addSuccessToast(summary, detail);

        seatingCharts.value = seatingCharts.value.filter(
          (sc) => sc.id != seatingChart.id
        );
        return true;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
        return false;
      }
    }
  }

  async function createSeatingChart(seatingChart: UploadSeatingChartDTO) {
    let summary = "Couldn't Create Seating Chart";
    let detail = "Something went wrong.";
    seatingChart.isPublished = false;
    const response = await seatingChartDataAccess.createSeatingChart(
      seatingChart
    );

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        const newChart = response.data;

        summary = "Seating Chart Created";
        detail = `${newChart.name} has been created.`;
        toast.addSuccessToast(summary, detail);

        seatingCharts.value.push(newChart);
        showCreatePopup();
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function publishSeatingChart(seatingChart: SeatingChart) {
    await saveSeatingChart(seatingChart);

    let summary = "Couldn't Publish Seating Chart";
    let detail = "Something went wrong.";
    const response = await seatingChartDataAccess.publishSeatingChartById(
      seatingChart.id
    );

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        const newChart = response.data;

        summary = "Seating Chart Published";
        detail = `${newChart.name} has been published.`;
        toast.addSuccessToast(summary, detail);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }

    await getSeatingCharts();
  }

  async function saveSeatingChart(seatingChart: SeatingChart) {
    let summary = "Couldn't Save Seating Chart";
    let detail = "Something went wrong.";
    const response = await seatingChartDataAccess.saveSeatingChart(
      seatingChart
    );

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        const newChart = response.data;

        summary = "Seating Chart Saved";
        detail = `${newChart.name} has been saved.`;
        toast.addSuccessToast(summary, detail);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function addFloorplanToChart(
    seatingChart: SeatingChart,
    floorplan: Floorplan
  ) {
    seatingChart.floorplans.push(floorplan);
  }

  function removeFloorplanFromChart(
    seatingChart: SeatingChart,
    floorplan: Floorplan
  ) {
    seatingChart.floorplans = seatingChart.floorplans.filter(
      (fp) => fp.id != floorplan.id
    );
    seatingChart.userFloorplanRectangleLinks =
      seatingChart.userFloorplanRectangleLinks.filter((link) =>
        seatingChartContainsRectangle(seatingChart, link.floorplanRectangleId)
      );
  }

  function seatingChartContainsRectangle(
    seatingChart: SeatingChart,
    rectId: number
  ) {
    for (const floorplan of seatingChart.floorplans) {
      for (const rect of floorplan.rectangles) {
        if (rect.id == rectId) return true;
      }
    }
    return false;
  }

  async function getSeatingCharts() {
    loading.value = true;
    const summary = "Couldn't Get Seating Charts";
    let detail = "Something went wrong.";
    const response = await seatingChartDataAccess.GetSeatingCharts();

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        seatingCharts.value = response.data;
        seatingCharts.value = seatingCharts.value.sort(sortByPublishedStatus);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
    loading.value = false;
  }

  function getPublishedSeatingChart() {
    const chart = seatingCharts.value.find((ch) => ch.isPublished);
    if (chart != null) {
      publishedSeatingChart.value = chart;
    }
  }

  function showCreatePopup() {
    showCreateSeatingChart.value = !showCreateSeatingChart.value;
  }

  function sortByPublishedStatus(
    chartOne: SeatingChart,
    chartTwo: SeatingChart
  ) {
    const flagA = chartOne.isPublished ? 1 : 0;
    const flagB = chartTwo.isPublished ? 1 : 0;

    return flagB - flagA;
  }

  async function getCommentsForSeatingChart(seatingChartId: number) {
    commentsLoading.value = true;
    const summary = "Couldn't Get Comments";
    let detail = "Something went wrong.";
    const response = await seatingChartDataAccess.getCommentsFromSeatingChart(
      seatingChartId
    );

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        comments.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
    commentsLoading.value = false;
  }

  async function createComment(comment: Comment) {
    let summary = "Couldn't Create Comment";
    let detail = "Something went wrong.";

    const response = await seatingChartDataAccess.createComment(comment);

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        await seatingChartDataAccess.getCommentsFromSeatingChart(
          comment.seatingChartId
        );

        summary = "Comment Created";
        detail = `The comment has been created.`;
        toast.addSuccessToast(summary, detail);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function deleteComment(commentId: number) {
    let summary = "Couldn't Delete Comment";
    let detail = "Something went wrong.";
    const response = await seatingChartDataAccess.deleteComment(commentId);
    if (!response) {
      toast.addErrorToast(summary, detail);
      return false;
    } else {
      if (response.success) {
        summary = "Comment Deleted";
        detail = `The comment has been deleted.`;
        toast.addSuccessToast(summary, detail);
        return true;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
        return false;
      }
    }
  }

  async function updateComment(comment: Comment) {
    let summary = "Couldn't Update Comment";
    let detail = "Something went wrong.";

    const response = await seatingChartDataAccess.updateComment(comment);

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Comment Updated";
        detail = `The comment has been updated.`;
        toast.addSuccessToast(summary, detail);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  return {
    showCreateSeatingChart,
    showAddFloorplanToChart,
    showCommentsPopUp,
    loading,
    commentsLoading,
    seatingCharts,
    comments,
    filters,
    showRemoveFloorplanFromChart,
    showCreateUserPopUp,
    showRenameChartPopUp,
    publishedSeatingChart,
    toggleShowAddFloorplanToChartPopUp,
    toggleShowCreateUserPopUp,
    createSeatingChart,
    addFloorplanToChart,
    showCreatePopup,
    deleteSeatingChart,
    toggleRemoveFloorplanPopup,
    removeFloorplanFromChart,
    toggleShowRenameChartPopUp,
    toggleShowCommentsPopUp,
    renameSeatingChart,
    saveSeatingChart,
    publishSeatingChart,
    seatingChartContainsRectangle,
    getCommentsForSeatingChart,
    createComment,
    deleteComment,
    updateComment,
  };
}
