import { ref } from "vue";
import { SkillLevel } from "@/types/types";
import skillDataAccess from "@/api/skillDataAccess";
import { useToastService } from "@/utils/ToastService";

export function useStrongestCategories(count = 5) {
  const skillLevels = ref<SkillLevel[]>([]);
  const categoryStrengths = new Map<string, number>();
  const chartData = ref({
    datasets: [
      {
        data: Array.from(categoryStrengths.values()),
        backgroundColor: [
          "#c3a4ff",
          "#6bffff",
          "#976ee6",
          "#5d39a2",
          "#0ea7a7",
        ],
      },
    ],
    labels: Array.from(categoryStrengths.keys()),
  });
  const toast = useToastService();

  function addSkillLevel(skillLevel: SkillLevel) {
    skillLevels.value.push(skillLevel);
    getCategoryStrengths();
  }

  function editSkillLevel(skillLevel: SkillLevel) {
    const index = skillLevels.value.findIndex(
      (x) =>
        x.skill.id == skillLevel.skill.id && x.user.id == skillLevel.user.id
    );
    if (index >= 0) {
      skillLevels.value[index].level = skillLevel.level;
    }
    getCategoryStrengths();
  }

  async function init() {
    await getUserSkills();
    getCategoryStrengths();
  }

  async function getUserSkills() {
    const summary = "Couldn't Get User Skills";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.getMySkillLevels();
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        skillLevels.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function getCategoryStrengths() {
    categoryStrengths.clear();
    if (skillLevels.value != undefined) {
      skillLevels.value.forEach((skillLevel: SkillLevel) => {
        const categoryName = skillLevel.skill.category.name;
        const currentValue = categoryStrengths.get(categoryName);
        if (currentValue != undefined) {
          const categoryStrength = currentValue + skillLevel.level;
          categoryStrengths.set(categoryName, categoryStrength);
        } else {
          categoryStrengths.set(categoryName, skillLevel.level);
        }
      });
      const categoryStrengthsObj = Object.fromEntries(categoryStrengths);
      const topFive = Object.entries(categoryStrengthsObj)
        .sort(([, val1], [, val2]) => val2 - val1)
        .slice(0, count);
      const topFiveMap = new Map(topFive);
      chartData.value.datasets[0].data = Array.from(topFiveMap.values());
      chartData.value.labels = Array.from(topFiveMap.keys());
    }
  }

  init();

  return {
    chartData,
    addSkillLevel,
    editSkillLevel,
  };
}
