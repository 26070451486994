import api from "@/api/api";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";
import { Game, GameGroup } from "@/types/game";

class GameDataAccess {
  async getGames(): Promise<DataResponse<GameGroup[]> | undefined> {
    try {
      const response = await api.get("Games");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getGameById(id: number): Promise<DataResponse<Game> | undefined> {
    try {
      const response = await api.get(`Games/game/${id}`);
      return response.data;
    } catch (error) {
      if(error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async uploadGame(
    uploadForm: FormData
  ): Promise<DataResponse<Game> | undefined> {
    try {
      const response = await api.post("Games", uploadForm, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async updateGame(
    updateForm: FormData
  ): Promise<DataResponse<Game> | undefined> {
    try {
      const response = await api.put("Games", updateForm, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      if(error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteGame(id: number): Promise<DataResponse<null> | undefined> {
    try {
      const response = await api.delete("Games", { data: { id } });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteMyGame(id: number): Promise<DataResponse<null> | undefined> {
    try {
      const response = await api.delete("Games/MyGame", { data: { id } });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async GetGroupedGamesByTags(
    tags: string[]
  ): Promise<DataResponse<GameGroup[]> | undefined> {
    try {
      const response = await api.post("Games/byTags", tags);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async GetTags(): Promise<DataResponse<any[]> | undefined> {
    try {
      const response = await api.get("Tags");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }
}

const gameDataAccess = new GameDataAccess();

export default gameDataAccess;
