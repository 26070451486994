import { ref } from "vue";
import slideshowDataAccess from "@/api/slideshowDataAccess";
import { Image, ImageUpload } from "@/types/image";
import { useToastService } from "@/utils/ToastService";
import { DataResponse } from "@/types/types";

export const useSlideshow = () => {
    const images = ref<Image[]>([]);
    const allImages = ref<Image[]>([]);
    const toast = useToastService();
    const slideshowLoading = ref(false);

    async function getImages() {
        slideshowLoading.value = true;

        let response: DataResponse<Image[]> | undefined;
        let response2: DataResponse<Image[]> | undefined;
        if(window.location.href.includes('accessToken')) {
            const urlParams = new URLSearchParams(window.location.search);
            let token = urlParams.get("accessToken");

            if(token == null)
            {
                token = "";
            }

            response = await slideshowDataAccess.getImagesWithAccessToken(token);
            response2 = await slideshowDataAccess.getFilteredImagesWithAccessToken(token);
        } else {
            response = await slideshowDataAccess.getImages();
            response2 = await slideshowDataAccess.getFilteredImages();
        }

        if(response && response2) {
            allImages.value = response.data;
            images.value = response2.data;
            
            toast.addSuccessToast('Loaded Slideshow Successfully', '');
        } else {
            toast.addErrorToast('Couldn\'t Load Slideshow Images', '');
        }

        slideshowLoading.value = false;
    }

    async function uploadImage(imageFile: FormData) {
        try {
            slideshowLoading.value = true;
            
            const response = await slideshowDataAccess.uploadImage(imageFile);

            if (response) {
                toast.addSuccessToast('Image uploaded successfully', '');
                await getImages();
            } else {
                toast.addErrorToast('Failed to upload image', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to upload image', '');
        } finally {
            slideshowLoading.value = false;
        }
    }

    async function deleteImage(imageId: number) {
        try {
            slideshowLoading.value = true;
            const response = await slideshowDataAccess.deleteImage(imageId);
            if (response) {
                toast.addSuccessToast('Image deleted successfully', '');
                await getImages();
            } else {
                toast.addErrorToast('Failed to delete image', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to delete image', '');
        } finally {
            slideshowLoading.value = false;
        }
    }

    init();
    async function init() {
        getImages();
    }
  
    return {
        images,
        allImages,
        slideshowLoading,
        uploadImage,
        deleteImage
    };
  };