import dashboardConfigDataAccess from "@/api/dashboardConfigDataAccess";
import { DashboardConfig } from "@/types/dashboardConfig";
import { DataResponse } from "@/types/types";
import { useToastService } from "@/utils/ToastService";
import { ref } from "vue";

export function useDashboardConfig() {
  const defaultConfig: DashboardConfig = {
    id: 0,
    imageDisplayInterval: 10
  }

  const config = ref<DashboardConfig>(defaultConfig);
  const toast = useToastService();
  const configLoading = ref<Boolean>(false);

  init();
  async function init() {
    await getConfig();
  }

  async function getConfig() {
    configLoading.value = true;
    const summary = "Couldn't Get Dashboard Config";
    let detail = "Something went wrong.";

    let response: DataResponse<DashboardConfig> | undefined;
    if(window.location.href.includes('accessToken')) {
        const urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get("accessToken");

        if(token == null)
        {
            token = "";
        }

        response = await dashboardConfigDataAccess.getConfigWithAccessToken(token);
    } else {
        response = await dashboardConfigDataAccess.getConfig();
    }

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        config.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
    configLoading.value = false;
  }

  async function updateConfig(configToUpdate: DashboardConfig) {
    configLoading.value = true;
    const summary = "Couldn't Update Dashboard Config";
    let detail = "Something went wrong.";
    const response = await dashboardConfigDataAccess.updateConfig(configToUpdate);

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        config.value = response.data;
        toast.addSuccessToast("Successfully Updated Dashboard Configuration", "");
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
    configLoading.value = false;
  }

  return {
    config,
    configLoading,
    updateConfig,
  };
}
