import { createStore, Store, useStore as baseUseStore } from "vuex";
import { InjectionKey } from "vue";
import { UserState, UserModule } from "@/store/userModule";
import { GameState, GameModule } from "./gameModule";

export interface State {
  userInfo: UserState;
  gameInfo: GameState;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  modules: {
    userInfo: UserModule,
    gameInfo: GameModule,
  },
});

export function useStore() {
  return baseUseStore(key);
}
