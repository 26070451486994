import { ref } from "vue";
import skillDataAccess from "@/api/skillDataAccess";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { Skill, SkillCategory, SkillLevel } from "@/types/types";
import { useToastService } from "@/utils/ToastService";

export function useMySkills() {
  const loading = ref(true);
  const categories = ref<SkillCategory[]>([]);
  const displayStaleSkills = ref<Boolean>(false);
  const skills = ref<Skill[]>([]);
  const staleSkills = ref<SkillLevel[]>([]);
  const reviewHeader = ref<string>();
  const toast = useToastService();
  const skillLevels = ref<SkillLevel[]>([]);
  const skillLevel = ref<SkillLevel>({
    user: {
      id: 0,
      graphId: "",
      firstName: "",
      lastName: "",
      email: "",
      positions: [],
      roles: [],
      skillLevels: [],
      active: true,
      deletedAt: undefined
    },
    skill: {
      id: 0,
      name: "",
      category: {
        id: 0,
        name: "",
        createdTimestamp: new Date(),
        modifiedTimestamp: new Date(),
      },
      usersWithSkill: [],
      createdTimestamp: new Date(),
      modifiedTimestamp: new Date(),
    },
    level: 1,
    createdTimestamp: new Date(),
    modifiedTimestamp: new Date(),
  });
  const showCreateSkill = ref<boolean>(false);
  const showAddSkillLevel = ref<boolean>(false);
  const categoryNames = ref<String[]>([]);
  const skillNames = ref<String[]>([]);
  const expandedRowGroups = ref();
  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    skillName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  init();
  /* Initialize Functions */
  async function init() {
    await getMySkills();
    await getCategories();
    await getSkills();
    await getReviewableSkills();
    loading.value = false;
  }

  async function getCategories() {
    const response = await skillDataAccess.getSkillCategories();

    categories.value = [];
    categoryNames.value = [];
    if (response != undefined) {
      response.data.forEach((category: SkillCategory) => {
        categoryNames.value.push(category.name);
        categories.value.push(category);
      });
    } else {
      toast.addErrorToast("Couldn't Get Categories", "Something went wrong");
    }
  }

  async function getMySkills() {
    const summary = "Couldn't Get User Skills";
    let detail = "Something went wrong.";
    const response = await skillDataAccess.getMySkillLevels();

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        skillLevels.value = response.data;
        sortSkillLevels();
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function getReviewableSkills() {
    const response = await skillDataAccess.getStaleSkills();
    if (response && response.success) {
      staleSkills.value = response.data;
      reviewHeader.value = `Review Stale Skills (${staleSkills.value.length})`;
    }
  }

  async function getSkills() {
    const summary = "Couldn't Get Skills";
    let detail = "Something went wrong.";
    const response = await skillDataAccess.getSkills();

    skills.value = [];
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        response.data.forEach((skill: Skill) => {
          if (!skillLevels.value.find((x) => x.skill.name === skill.name)) {
            skillNames.value.push(skill.name);
          }
          skills.value.push({
            id: skill.id,
            name: skill.name,
            category: skill.category,
            usersWithSkill: [],
            createdTimestamp: skill.createdTimestamp,
            modifiedTimestamp: skill.modifiedTimestamp,
          });
        });
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function checkCreateSkill(skill: Skill) {
    if (skill.name === "") {
      toast.addErrorToast(
        "Couldn't Create Skill",
        "Please enter a skill name."
      );
      return false;
    } else if (skill.category.name == "") {
      toast.addErrorToast("Couldn't Create Skill", "Please select a category.");
      return false;
    }
    return true;
  }

  function sortSkillLevels() {
    skillLevels.value = skillLevels.value.sort(function (a, b) {
      if (a.skill.category.name < b.skill.category.name) return -1;
      if (a.skill.category.name > b.skill.category.name) return 1;
      return 0;
    });
  }

  /* Component Functions */
  async function addSkillLevel() {
    const summary = "Couldn't Add Skill Level";
    let detail = "Something went wrong.";

    if (skillLevel.value.skill.name === "") {
      detail = "Please select a skill to add";
      toast.addErrorToast(summary, detail);
      return;
    }

    const foundSkill = skills.value.find(skill => skill.name === skillLevel.value.skill.name);
    if(foundSkill !== undefined) {
      skillLevel.value.skill = foundSkill;
    }

    const response = await skillDataAccess.addSkillLevelToMe(skillLevel.value);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        toast.addSuccessToast("Successfully added Skill Level", "");
        skillLevels.value.push(response.data);
        skillNames.value = skillNames.value.filter(
          (x) => x !== response.data.skill.name
        );
        sortSkillLevels();
        showAddSkillLevelPopup();
        skillLevel.value = {
          user: {
            id: 0,
            graphId: "",
            firstName: "",
            lastName: "",
            email: "",
            positions: [],
            roles: [],
            skillLevels: [],
            active: true,
            deletedAt: undefined
          },
          skill: {
            id: 0,
            name: "",
            category: {
              id: 0,
              name: "",
              createdTimestamp: new Date(),
              modifiedTimestamp: new Date(),
            },
            usersWithSkill: [],
            createdTimestamp: new Date(),
            modifiedTimestamp: new Date(),
          },
          level: 1,
          createdTimestamp: new Date(),
          modifiedTimestamp: new Date(),
        };
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function clearFilters() {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      categoryName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      skillName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    };
  }

  async function createSkill(skill: Skill) {
    let summary = "Couldn't Create Skill";
    let detail = "Something went wrong.";
    const check = checkCreateSkill(skill);
    if (!check) return;

    const categoryID = categories.value.find(
      (c) => c.name == skill.category.name
    )?.id;

    if (categoryID == undefined) {
      detail = `Category: ${skill.category.name} not found`;
      toast.addErrorToast(summary, detail);
      return;
    }

    skill.category.id = categoryID;
    const response = await skillDataAccess.createSkill(skill);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        skillNames.value.push(response.data.name);
        skills.value.push({
          id: response.data.id,
          name: response.data.name,
          category: response.data.category,
          usersWithSkill: [],
          createdTimestamp: response.data.createdTimestamp,
          modifiedTimestamp: response.data.modifiedTimestamp,
        });

        summary = "Skill Created";
        detail = `${skill.name} has been created.`;
        toast.addSuccessToast(summary, detail);
        showCreatePopup();
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function deleteSkillLevel(skillLevel: SkillLevel) {
    const summary = "Couldn't Delete Skill";
    const detail = "Something went wrong.";
    const response = await skillDataAccess.deleteSkillLevel(skillLevel);

    if (response && response.success) {
      skillNames.value.push(skillLevel.skill.name);
      skillLevels.value = skillLevels.value.filter(
        (x) => x.skill.name !== skillLevel.skill.name
      );
      return true;
    } else {
      toast.addErrorToast(summary, detail);
    }
    return false;
  }

  async function editSkillLevel(skillLevel: SkillLevel) {
    let summary = "Couldn't Update Skill Level";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.editSkillLevel(skillLevel);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Updated Skill Level";
        detail = `Updated ${response.data.skill.name} to level ${response.data.level}.`;
        toast.addSuccessToast(summary, detail);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
    return false;
  }

  async function reviewSkill(skillLevel: SkillLevel) {
    let summary = "Couldn't Update Stale Skill";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.editSkillLevel(skillLevel);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Updated Stale Skill Level";
        detail = `Updated ${response.data.skill.name} to level ${response.data.level}.`;
        toast.addSuccessToast(summary, detail);

        staleSkills.value = staleSkills.value.filter(
          (x) => x.skill.name !== skillLevel.skill.name
        );
        const index = skillLevels.value.findIndex(
          (x) => x.skill.name === skillLevel.skill.name
        );
        skillLevels.value[index] = response.data;
        reviewHeader.value = `Review Stale Skills (${staleSkills.value.length})`;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function reviewSkills() {
    showStaleSkills();

    let summary = "Couldn't Update Stale Skill Levels";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.editSkillLevels(staleSkills.value);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Updated Stale Skill Levels";
        detail = `Updated ${staleSkills.value.length} skill levels.`;
        toast.addSuccessToast(summary, detail);

        staleSkills.value.forEach((s) => {
          const index = skillLevels.value.findIndex(
            (x) => x.skill.name == s.skill.name
          );

          skillLevels.value[index] = s;
        });
        staleSkills.value = [];
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function showCreatePopup() {
    showCreateSkill.value = !showCreateSkill.value;
  }

  function showAddSkillLevelPopup() {
    showAddSkillLevel.value = !showAddSkillLevel.value;
    skillLevel.value = {
      user: {
        id: 0,
        graphId: "",
        firstName: "",
        lastName: "",
        email: "",
        positions: [],
        roles: [],
        skillLevels: [],
        active: true,
        deletedAt: undefined
      },
      skill: {
        id: 0,
        name: "",
        category: {
          id: 0,
          name: "",
          createdTimestamp: new Date(),
          modifiedTimestamp: new Date(),
        },
        usersWithSkill: [],
        createdTimestamp: new Date(),
        modifiedTimestamp: new Date(),
      },
      level: 1,
      createdTimestamp: new Date(),
      modifiedTimestamp: new Date(),
    };
  }

  function showStaleSkills() {
    displayStaleSkills.value = !displayStaleSkills.value;
  }

  return {
    categoryNames,
    displayStaleSkills,
    expandedRowGroups,
    filters,
    reviewHeader,
    showAddSkillLevel,
    showCreateSkill,
    skillLevel,
    skillLevels,
    skillNames,
    staleSkills,
    addSkillLevel,
    clearFilters,
    createSkill,
    deleteSkillLevel,
    editSkillLevel,
    reviewSkill,
    reviewSkills,
    showAddSkillLevelPopup,
    showStaleSkills,
    showCreatePopup,
    sortSkillLevels,
    loading,
  };
}
