import api from "@/api/api";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";
import { Event } from "@/types/event";

class EventDataAccess {
    async getEvents(): Promise<DataResponse<Event[]> | undefined> {
        try {
          const response = await api.get("Event");
          return response.data;
        } catch (error) {
          console.error(error);
          if (error instanceof AxiosError) {
            return error.response?.data;
          }
        }
    }

    async getEventsWithAccessToken(token: string): Promise<DataResponse<Event[]> | undefined> {
      try {
        const response = await api.get(`Event/access?accessToken=${token}`);
        return response.data;
      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          return error.response?.data;
        }
      }
  }

    

    async getSelectedEvents(): Promise<DataResponse<Event[]> | undefined> {
        try {
          const response = await api.get("Event/selected");

          return response.data;
        } catch (error) {
          console.error(error);
          if (error instanceof AxiosError) {
            return error.response?.data;
          }
        }
    }

    async createEvent(event: FormData): Promise<DataResponse<Event> | undefined> {
        try {
            const response = await api.post("Event", event, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                return error.response?.data;
            }
        }
    }

    async deleteEvent(eventId: number): Promise<DataResponse<null> | undefined> {
        try {
          const resposne = await api.delete(`Event/${eventId}`);
          return resposne.data;
        } catch (error) {
          console.error(error);
          if (error instanceof AxiosError) {
            return error.response?.data;
          }
        }
    }

    async selectEvent(eventId: number): Promise<DataResponse<null> | undefined> {
        try {
            const resposne = await api.put(`Event/${eventId}`);
            return resposne.data;
          } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
              return error.response?.data;
            }
          }
    }

    async deselectEvent(eventId: number): Promise<DataResponse<null> | undefined> {
      try {
          const resposne = await api.put(`Event/deselect/${eventId}`);
          return resposne.data;
        } catch (error) {
          console.error(error);
          if (error instanceof AxiosError) {
            return error.response?.data;
          }
        }
  }
}

const eventDataAccess = new EventDataAccess();

export default eventDataAccess;