import { Module } from "vuex";
import userDataAccess from "@/api/userDataAccess";
import { Position, Role } from "@/types/types";
import { State } from "@/store";

export interface UserState {
  id: number;
  userName: String;
  positions: Position[];
  roles: Role[];
  profileImage: String;
  userEmail: String;
}

export const UserModule: Module<UserState, State> = {
  state: () => ({
    id: 0,
    userName: "First Last",
    userEmail: "",
    positions: [],
    roles: [],
    profileImage: "",
  }),
  mutations: {
    setUserID(state, newID: number) {
      state.id = newID;
    },
    setUserName(state, newUserName: string) {
      state.userName = newUserName;
    },
    setUserPositions(state, newUserPositions: Position[]) {
      state.positions = newUserPositions;
    },
    setUserRoles(state, newUserRoles: Role[]) {
      state.roles = newUserRoles;
    },
    setProfileImage(state, newProfileImage: string) {
      state.profileImage = newProfileImage;
    },
    setUserEmail(state, newUserEmail: string) {
      state.userEmail = newUserEmail;
    },
  },
  actions: {
    async loadUserInfo({ commit }) {
      await userDataAccess.getUserInfo().then(async (response) => {
        if (response && response.success) {
          const userInfo = response.data;
          commit("setUserID", userInfo.id);
          commit("setUserName", `${userInfo.firstName} ${userInfo.lastName}`);
          commit("setUserEmail", userInfo.email);
          commit("setUserPositions", userInfo.positions);
          commit("setUserRoles", userInfo.roles);
        }
      });
      await userDataAccess.getUserProfileImage().then(async (image) => {
        commit("setProfileImage", image);
      });
    },

    async setUserPositions({ commit }, newPositions: Position[]) {
      return userDataAccess.setUserPositions(newPositions).then((success) => {
        if (success) {
          commit("setUserPositions", newPositions);
        }
        return new Promise((resolve) => resolve(success));
      });
    },
  },
};
