import { SkillLevel, DataResponse, Response } from "@/types/types";
import skillDataAccess from "@/api/skillDataAccess";
import { ref } from "vue";
import { useToastService } from "@/utils/ToastService";

export function useMySkills() {
  const showStaleSkillsPopup = ref<Boolean>(false);
  const loading = ref(true);
  const skillLevels = ref<SkillLevel[]>([]);
  const staleSkills = ref<SkillLevel[]>([]);
  const reviewHeader = ref<string>();

  const toast = useToastService();

  function addSkillLevel(skillLevel: SkillLevel) {
    skillLevels.value.push(skillLevel);
    sortSkillLevels();
  }

  async function deleteSkillLevel(
    skillLevel: SkillLevel
  ): Promise<Response | undefined> {
    const summary = "Couldn't Delete Skill";
    const detail = "Something went wrong.";
    const response = await skillDataAccess.deleteSkillLevel(skillLevel);

    if (response && response.success) {
      skillLevels.value = skillLevels.value.filter(
        (x) => x.skill.name !== skillLevel.skill.name
      );

      return response;
    } else {
      toast.addErrorToast(summary, detail);
    }
  }

  async function editSkillLevel(
    skillLevel: SkillLevel
  ): Promise<DataResponse<SkillLevel> | undefined> {
    let summary = "Couldn't Update Skill Level";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.editSkillLevel(skillLevel);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Updated Skill Level";
        detail = `Updated ${response.data.skill.name} to level ${response.data.level}.`;
        toast.addSuccessToast(summary, detail);

        return response;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function reviewSkill(
    skillLevel: SkillLevel
  ): Promise<DataResponse<SkillLevel> | undefined> {
    let summary = "Couldn't Review Stale Skill";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.editSkillLevel(skillLevel);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        staleSkills.value = staleSkills.value.filter(
          (x) => x.skill.name !== skillLevel.skill.name
        );
        const index = skillLevels.value.findIndex(
          (x) => x.skill.name === skillLevel.skill.name
        );
        skillLevels.value[index] = response.data;
        reviewHeader.value = `Review Stale Skills (${staleSkills.value.length})`;

        summary = "Reviewed Stale Skill Level";
        detail = `Updated ${skillLevel.skill.name} to level ${skillLevel.level}.`;
        toast.addSuccessToast(summary, detail);

        return response;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function reviewSkills(): Promise<
    DataResponse<SkillLevel[]> | undefined
  > {
    toggleStaleSkillsPopup();

    let summary = "Couldn't Update Stale Skill Levels";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.editSkillLevels(staleSkills.value);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        staleSkills.value.forEach((s) => {
          const index = skillLevels.value.findIndex(
            (x) => x.skill.name == s.skill.name
          );

          skillLevels.value[index] = s;
        });
        staleSkills.value = [];

        summary = "Updated Stale Skill Levels";
        detail = `Updated ${staleSkills.value.length} skill levels.`;
        toast.addSuccessToast(summary, detail);

        return response;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function toggleStaleSkillsPopup() {
    showStaleSkillsPopup.value = !showStaleSkillsPopup.value;
  }

  async function init() {
    await getMySkillLevels();
    await getReviewableSkills();

    loading.value = false;
  }

  async function getMySkillLevels() {
    const summary = "Couldn't Get User Skills";
    let detail = "Something went wrong.";
    const response = await skillDataAccess.getMySkillLevels();

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        skillLevels.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
    sortSkillLevels();
  }

  function sortSkillLevels() {
    skillLevels.value = skillLevels.value.sort(function (a, b) {
      if (a.skill.category.name < b.skill.category.name) return -1;
      if (a.skill.category.name > b.skill.category.name) return 1;
      return 0;
    });
  }

  async function getReviewableSkills() {
    const response = await skillDataAccess.getStaleSkills();
    if (response && response.success) {
      staleSkills.value = response.data;
      reviewHeader.value = `Review Stale Skills (${staleSkills.value.length})`;
    }
  }

  init();

  return {
    loading,
    reviewHeader,
    skillLevels,
    staleSkills,
    showStaleSkillsPopup,
    addSkillLevel,
    deleteSkillLevel,
    editSkillLevel,
    reviewSkill,
    reviewSkills,
    toggleStaleSkillsPopup,
  };
}
