import api from "@/api/api";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";
import {
  Floorplan,
  Location,
  UploadFloorplanDTO,
  UpdateFloorplanDTO,
  SeatingChart,
  UploadSeatingChartDTO,
} from "@/types/seatingChart";
import { Comment } from "@/types/comment";

class SeatingChartDataAccess {
  async createSeatingChart(
    data: UploadSeatingChartDTO
  ): Promise<DataResponse<SeatingChart> | undefined> {
    try {
      const response = await api.post("SeatingCharts/Create", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async saveSeatingChart(
    data: SeatingChart
  ): Promise<DataResponse<SeatingChart> | undefined> {
    try {
      const response = await api.post("SeatingCharts/Update", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async uploadFloorPlan(
    data: UploadFloorplanDTO
  ): Promise<DataResponse<Floorplan> | undefined> {
    try {
      const response = await api.post("Floorplans/Create", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async updateFloorPlan(
    data: UpdateFloorplanDTO
  ): Promise<DataResponse<Floorplan> | undefined> {
    try {
      const response = await api.post("Floorplans/Update", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async GetLocations(): Promise<DataResponse<Location[]> | undefined> {
    try {
      const response = await api.get("Locations");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async GetFloorPlans(): Promise<DataResponse<Floorplan[]> | undefined> {
    try {
      const response = await api.get("Floorplans");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async GetSeatingCharts(): Promise<DataResponse<SeatingChart[]> | undefined> {
    try {
      const response = await api.get("SeatingCharts");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async DeleteFloorPlan(id: number): Promise<DataResponse<null> | undefined> {
    try {
      const response = await api.delete(`Floorplans/Delete/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteSeatingChart(
    id: number
  ): Promise<DataResponse<null> | undefined> {
    try {
      const response = await api.delete(`SeatingCharts/Delete?id=${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async publishSeatingChartById(
    id: number
  ): Promise<DataResponse<SeatingChart> | undefined> {
    try {
      const response = await api.post(`SeatingCharts/Publish?id=${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getCommentsFromSeatingChart(
    seatingChartId: number
  ): Promise<DataResponse<Comment[]> | undefined> {
    try {
      const response = await api.get(`Comments/${seatingChartId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async createComment(
    data: Comment
  ): Promise<DataResponse<Comment> | undefined> {
    try {
      const response = await api.post("Comments/Create", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async updateComment(
    data: Comment
  ): Promise<DataResponse<Comment> | undefined> {
    try {
      const response = await api.post("Comments/Update", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteComment(
    commentId: number
  ): Promise<DataResponse<null> | undefined> {
    try {
      const response = await api.delete(`Comments/${commentId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }
}

const seatingChartDataAccess = new SeatingChartDataAccess();

export default seatingChartDataAccess;
