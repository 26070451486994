import { ref } from "vue";
import { Skill } from "@/types/types";
import skillDataAccess from "@/api/skillDataAccess";
import { useToastService } from "@/utils/ToastService";

export function useNewSkills(count = 5) {
  const newSkills = ref<Skill[]>([]);
  const toast = useToastService();

  async function update() {
    await getNewSkills();
  }

  async function init() {
    await getNewSkills();
  }

  async function getNewSkills() {
    const summary = "Couldn't Get Recently Added Skills";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.getNewSkills(count);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        newSkills.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  init();

  return {
    newSkills,
    update,
  };
}
