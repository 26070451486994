import * as msal from "@azure/msal-browser";
import msalConfig from "./msalConfig";

class Authentication {
  msalInstance: msal.PublicClientApplication;
  account: msal.AccountInfo | null;
  loginRequest: msal.PopupRequest;
  loginRedirectRequest: msal.RedirectRequest;
  profileRequest: msal.PopupRequest;
  profileRedirectRequest: msal.RedirectRequest;
  apiRequest: msal.PopupRequest;
  apiRedirectRequest: msal.RedirectRequest;
  silentLoginRequest: msal.SsoSilentRequest;
  silentProfileRequest: msal.SilentRequest;
  silentApiRequest: msal.SilentRequest;

  constructor() {
    this.msalInstance = new msal.PublicClientApplication(msalConfig);
    this.account = null;

    this.loginRequest = {
      scopes: [],
    };
    this.loginRedirectRequest = {
      ...this.loginRequest,
    };
    this.profileRequest = {
      scopes: ["User.Read.All"],
    };
    this.profileRedirectRequest = {
      ...this.profileRequest,
    };
    this.apiRequest = {
      scopes: [`${process.env.VUE_APP_CLIENT_ID}/.default`],
    };
    this.apiRedirectRequest = {
      ...this.apiRequest,
    };

    this.silentLoginRequest = {
      loginHint: "flast@omnitech-inc.com",
    };
    this.silentProfileRequest = {
      scopes: ["openid", "profile", "User.Read.All"],
      forceRefresh: false,
    };
    this.silentApiRequest = {
      ...this.apiRequest,
      forceRefresh: false,
    };
  }

  async loadAuthModule() {
    if (window.location.href.includes('accessToken')) {
      return;
    }

    await this.handleRedirectPromise();
    this.account = this.getAccount();
    
    if (!this.account) {
      await this.login();
    }
    
  }

  async handleRedirectPromise() {
    try {
      const response = await this.msalInstance.handleRedirectPromise();
      this.handleRedirectResponse(response);
    } catch (e) {
      console.error(e);
    }
  }

  handleRedirectResponse(response: msal.AuthenticationResult | null): void {
    if (response) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }
  }

  async login() {
    await this.msalInstance.loginRedirect(this.loginRedirectRequest);
  }

  async logout() {
    let account: msal.AccountInfo | undefined;
    if (this.account) {
      account = this.account;
    }

    const logoutRequest = {
      account,
    };

    await this.msalInstance.logoutRedirect(logoutRequest);
  }

  private getAccount(): msal.AccountInfo | null {
    const currentAccounts = this.msalInstance.getAllAccounts();

    if (currentAccounts.length === 0) {
      console.warn("No accounts detected.");
      return null;
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected."); // TODO: add choose account
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }

    return null;
  }

  async getProfileTokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account;
    }

    return this.getTokenRedirect(
      this.silentProfileRequest,
      this.profileRedirectRequest
    );
  }

  async getApiTokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.silentApiRequest.account = this.account;
    }

    return this.getTokenRedirect(
      this.silentApiRequest,
      this.apiRedirectRequest
    );
  }

  private async getTokenRedirect(
    silentRequest: msal.SilentRequest,
    interactiveRequest: msal.RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.msalInstance.acquireTokenSilent(
        silentRequest
      );
      return response.accessToken;
    } catch (e) {
      console.warn(
        "Silent token acquisition failed. Attempting to acquire token via redirect. Error message: " +
          e
      );
      this.msalInstance
        .acquireTokenRedirect(interactiveRequest)
        .catch(console.error);
    }

    return null;
  }
}

const auth = new Authentication();

export default auth;
