<template>
    <div class="background">
        <img id="left-image" src="https://saweatherstation.blob.core.windows.net/images/notFoundLeft.svg" />
        <p><span class="large-text">No weather data available</span></p>
        <img id="right-image" src="https://saweatherstation.blob.core.windows.net/images/notFoundRight.svg" />
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.background {
    background-image: url('https://saweatherstation.blob.core.windows.net/images/notFoundBackground.jpg');
    background-size: cover;
    display: flex;
    justify-content: space-between;
}

p {
    font-size: 1vw;
    color: white;
    align-self: center;
}

.large-text {
    font-size: 1.5vw;
}

@media screen and (max-width: 1262px) {
    p {
        font-size: 1.3vw;
    }

    .large-text {
        font-size: 2vw;
    }
}

@media screen and (max-width: 700px) {
    .background {
        justify-content: space-around;
    }

    p {
        font-size: 2.4vw;
    }

    .large-text {
        font-size: 3.5vw;
    }

    #right-image {
        display: none;
    }

    #left-image {
        height: 168px;
        width: 40vw;
    }

}
</style>