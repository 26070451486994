import skillDataAccess from "@/api/skillDataAccess";
import userDataAccess from "@/api/userDataAccess";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { SkillLevel } from "@/types/types";
import { ref } from "vue";

export function useViewEngineer(userID: string) {
  const userSkillLevels = ref<SkillLevel[]>([]);
  const engineerName = ref<string>();
  const loading = ref(true);
  async function getSkillLevels() {
    const response = await skillDataAccess.getEngineerSkills(+userID);
    if (response != undefined) {
      userSkillLevels.value = response.data;
    }
    sortSkillLevels();
    loading.value = false;
  }
  async function getUserInfo() {
    const response = await userDataAccess.getUser(+userID);
    if (response != undefined) {
      const engineer = response.data;
      engineerName.value = engineer.firstName + " " + engineer.lastName;
    }
  }
  const expandedRowGroups = ref();
  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    skillName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  function clearFilters() {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      categoryName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      skillName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    };
  }

  function sortSkillLevels() {
    userSkillLevels.value = userSkillLevels.value.sort(function (a, b) {
      if (a.skill.category.name < b.skill.category.name) return -1;
      if (a.skill.category.name > b.skill.category.name) return 1;
      return 0;
    });
  }

  getSkillLevels();
  getUserInfo();
  return {
    userSkillLevels,
    clearFilters,
    filters,
    expandedRowGroups,
    engineerName,
    loading,
  };
}
