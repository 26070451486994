import { ref, watch } from "vue";
import { Skill, SkillCategory } from "@/types/types";
import skillDataAccess from "@/api/skillDataAccess";
import { useToastService } from "@/utils/ToastService";

export function useAdminSkills() {
  const showCreateSkill = ref<Boolean>(false);
  const showEditSkill = ref<Boolean>(false);
  const showCreateCategory = ref<Boolean>(false);
  const showEditCategory = ref<Boolean>(false);
  const merge = ref<Boolean>(false);
  const categories = ref<SkillCategory[]>([]);
  const skills = ref<Skill[]>([]);
  const categoryNames = ref<String[]>([]);
  const toast = useToastService();
  const loading = ref(true);

  initialize();
  /* Initialize Functions */
  async function initialize() {
    await getCategories();
    await getSkills();
  }

  async function getCategories() {
    const summary = "Couldn't Get Categories";
    const detail = "Something went wrong.";
    const response = await skillDataAccess.getSkillCategories();

    categories.value = [];
    categoryNames.value = [];
    if (response != undefined) {
      response.data.forEach((category: SkillCategory) => {
        categoryNames.value.push(category.name);
        categories.value.push(category);
      });
    } else {
      toast.addErrorToast(summary, detail);
    }
  }

  async function getSkills() {
    const summary = "Couldn't Get Skills";
    const detail = "Something went wrong.";
    const response = await skillDataAccess.getSkills();

    skills.value = [];
    if (response != undefined) {
      response.data.forEach((skill: Skill) => {
        skills.value.push({
          id: skill.id,
          name: skill.name,
          category: skill.category,
          usersWithSkill: [],
          createdTimestamp: skill.createdTimestamp,
          modifiedTimestamp: skill.modifiedTimestamp,
        });
      });
    } else {
      toast.addErrorToast(summary, detail);
    }
    loading.value = false;
  }

  function checkCreateSkill(skill: Skill) {
    const summary = "Couldn't Create Skill";
    let detail = "Please enter a skill name.";
    if (skill.name == "") {
      toast.addErrorToast(summary, detail);
      return false;
    } else if (skill.category.name == "") {
      detail = "Please select a category.";
      toast.addErrorToast(summary, detail);
      return false;
    }
    return true;
  }

  /* Component Functions */
  /* Skill Functions */
  async function createSkill(skill: Skill) {
    const check = checkCreateSkill(skill);
    if (!check) return;
    let summary = "Couldn't Create Skill";
    let detail = "Something went wrong.";

    const categoryID = categories.value.find(
      (c) => c.name == skill.category.name
    )?.id;

    if (categoryID == undefined) {
      detail = `Category: ${skill.category.name} not found`;
      toast.addErrorToast(summary, detail);
      return;
    }

    skill.category.id = categoryID;
    const response = await skillDataAccess.createSkill(skill);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Skill Created";
        detail = `${skill.name} has been created.`;
        skills.value.push({
          id: response.data.id,
          name: response.data.name,
          category: response.data.category,
          usersWithSkill: [],
          createdTimestamp: response.data.createdTimestamp,
          modifiedTimestamp: response.data.modifiedTimestamp,
        });
        toast.addSuccessToast(summary, detail);
        showCreateSkill.value = false;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function deleteSkill(skillID: number) {
    const summary = "Couldn't Delete Skill";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.deleteSkill(skillID);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        skills.value = skills.value.filter((s) => s.id !== skillID);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function editSkill(skill: Skill) {
    const check = checkCreateSkill(skill);
    if (!check) return;
    let summary = "Couldn't Update Skill";
    let detail = "Something went wrong.";

    const categoryID = categories.value.find(
      (c) => c.name == skill.category.name
    )?.id;

    if (categoryID == undefined) {
      detail = `Category: ${skill.category.name} not found`;
      toast.addErrorToast(summary, detail);
      return;
    }

    const response = await skillDataAccess.editSkill(skill);

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Skill Updated";
        detail = `${skill.name} has been updated.`;

        skills.value.forEach((s) => {
          if (s.id === response.data.id) {
            s.name = response.data.name;
            s.category = response.data.category;
          }
        });
        toast.addSuccessToast(summary, detail);
        showEditSkill.value = false;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function mergeSkills(skills: Skill[], newSkill: Skill) {
    let summary = "Cleanup Failed";
    let detail = "Something went wrong.";

    const newSkillCategory = categories.value.find(
      (c) => c.name == newSkill.category.name
    );
    if (newSkillCategory == undefined) {
      detail = "Invalid category.";
      toast.addErrorToast(summary, detail);
      return;
    }
    newSkill.category = newSkillCategory;

    const response = await skillDataAccess.mergeSkills(skills, newSkill);

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Cleanup Complete";
        detail = `${newSkill.name} is the new skill.`;
        toast.addSuccessToast(summary, detail);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }

    showCreateSkillPopup(false);
    initialize();
  }

  function showCreateSkillPopup(mergeValue: Boolean) {
    if (mergeValue === true) merge.value = true;
    else merge.value = false;
    showCreateSkill.value = !showCreateSkill.value;
  }

  function showEditSkillPopup() {
    showEditSkill.value = !showEditSkill.value;
  }

  /* Category Functions */
  async function createCategory(name: string) {
    if (name == "") {
      toast.addErrorToast(
        "Couldn't Create Category",
        "Please enter a category name"
      );
      return;
    }
    const response = await skillDataAccess.createCategory(name);
    let summary = "Couldn't Create Category";
    let detail = "Something went wrong.";

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Category Created!";
        detail = `${name} has been created.`;
        toast.addSuccessToast(summary, detail);
        categories.value.push(response.data);
        showCreateCategory.value = false;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function deleteCategory(category: number) {
    const summary = "Couldn't Delete Category";
    let detail = "Something went wrong.";
    const hasSkills = skills.value.find((s) => s.category.id == category);
    if (hasSkills != undefined) {
      detail = `Please remove the skills under the category to delete it.`;
      toast.addErrorToast(summary, detail);
      return;
    }

    const response = await skillDataAccess.deleteCategory(category);

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        categories.value = categories.value.filter((c) => c.id !== category);
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function editCategory(categoryID: number, name: string) {
    let summary = "Couldn't Update Category";
    let detail = "Something went wrong.";

    if (name == "") {
      detail = "Please enter a new category name";
      toast.addErrorToast(summary, detail);
      return;
    }

    const response = await skillDataAccess.editCategory(categoryID, name);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        summary = "Category Updated!";
        detail = `${name} has been updated.`;
        categories.value.forEach((c) => {
          if (c.id === response.data.id) {
            c.name = response.data.name;
            c.modifiedTimestamp = response.data.modifiedTimestamp;
          }
        });
        skills.value.forEach((s) => {
          if (s.category.id === response.data.id)
            s.category.name = response.data.name;
        });
        toast.addSuccessToast(summary, detail);
        showEditCategory.value = false;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function showCreateCategoryPopup() {
    showCreateCategory.value = !showCreateCategory.value;
  }

  function showEditCategoryPopup() {
    showEditCategory.value = !showEditCategory.value;
  }

  watch(
    () => [...categories.value],
    () => {
      categoryNames.value = [];
      categories.value.forEach((c) => {
        categoryNames.value.push(c.name);
      });
    },
    {
      deep: true,
    }
  );

  return {
    categories,
    categoryNames,
    merge,
    showCreateSkill,
    showEditSkill,
    showCreateCategory,
    showEditCategory,
    skills,
    createSkill,
    editSkill,
    deleteSkill,
    mergeSkills,
    createCategory,
    editCategory,
    deleteCategory,
    showCreateSkillPopup,
    showEditSkillPopup,
    showCreateCategoryPopup,
    showEditCategoryPopup,
    loading,
  };
}
