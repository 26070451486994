import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";
import { Announcement } from "@/types/announcement";
import api from "@/api/api";

class AnnouncementDataAccess {
    async createAnnouncement(announcement: Announcement): Promise<DataResponse<Announcement> | undefined> {
        try {
            const response = await api.post("Announcement", announcement, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
        catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                return error.response?.data;
            }
        }
    }

    async getAnnouncements(): Promise<DataResponse<Announcement[]> | undefined> {
        try {

            const response = await api.get("Announcement");
            return response.data;
        } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                return error.response?.data;
            }
        }
    }

    async getAnnouncementsWithAccessToken(token: string): Promise<DataResponse<Announcement[]> | undefined> {
        try {
            const response = await api.get(`Announcement/access?accessToken=${token}`);
            return response.data;
        } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                return error.response?.data;
            }
        }
    }

    async updateAnnouncement(announcement: Announcement): Promise<DataResponse<Announcement> | undefined> {
        try {
            const response = await api.patch("Announcement", announcement, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                return error.response?.data;
            }
        }
    }

    async deleteAnnouncement(announcementId: number): Promise<DataResponse<null> | undefined> {
        try {
            const response = await api.delete(`Announcement/${announcementId}`);
            return response.data;
        } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                return error.response?.data;
            }
        }
    }
}
  
const announcementDataAccess = new AnnouncementDataAccess();
  
export default announcementDataAccess;
  