import { ref } from "vue";

export function useThemes() {
  const darkMode = ref(darkModeEnabled());

  function setCookie(name: string, value: string, days: number) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  }

  function handleDarkModeClick() {
    darkMode.value = !darkMode.value;
    updateTheme();
  }

  function darkModeEnabled() {
    const cookies = document.cookie.split(";");
    const darkModeCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("dark-mode=")
    );
    return darkModeCookie ? darkModeCookie.split("=")[1] === "true" : false;
  }

  function updateTheme() {
    if (darkMode.value) {
      document.documentElement.setAttribute("data-theme", "dark");
      setCookie("dark-mode", "true", 30);
    } else {
      document.documentElement.removeAttribute("data-theme");
      setCookie("dark-mode", "false", 30);
    }
    updateIcon();
  }

  function updateIcon() {
    if (darkMode.value) {
      document.getElementById("theme-icon")?.classList.add("pi-moon");
      document.getElementById("theme-icon")?.classList.remove("pi-sun");
    } else {
      document.getElementById("theme-icon")?.classList.add("pi-sun");
      document.getElementById("theme-icon")?.classList.remove("pi-moon");
    }
  }

  return { updateTheme, handleDarkModeClick, darkMode, darkModeEnabled };
}
