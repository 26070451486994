import { Module } from "vuex";
import { State } from "@/store";

export interface GameState {
  refreshGames: Boolean;
}

export const GameModule: Module<GameState, State> = {
  state: () => ({
    refreshGames: false,
  }),
  mutations: {
    setRefreshGames(state, flag: boolean) {
      state.refreshGames = flag;
    },
  },
  actions: {
    setRefreshFlag({ commit }, flag: boolean) {
      commit("setRefreshGames", flag);
    },
  },
};
