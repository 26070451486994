import { useToast } from "primevue/usetoast";
import { ref } from "vue";

export type ToastSeverity = "success" | "info" | "warn" | "error" | undefined;

export function useToastService() {
  const displayDuration = ref<number>(2000);
  const toast = useToast();

  function addToast(severity: ToastSeverity, summary: string, detail: string) {
    toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: displayDuration.value,
    });
  }

  function addToastWithDuration(
    severity: ToastSeverity,
    summary: string,
    detail: string,
    life: number
  ) {
    toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: life,
    });
  }

  function addErrorToast(summary: string, detail: string) {
    toast.add({
      severity: "error",
      summary: summary,
      detail: detail,
      life: displayDuration.value,
    });
  }

  function addSuccessToast(summary: string, detail: string) {
    toast.add({
      severity: "success",
      summary: summary,
      detail: detail,
      life: displayDuration.value,
    });
  }

  return {
    addToast,
    addToastWithDuration,
    addErrorToast,
    addSuccessToast,
  };
}
