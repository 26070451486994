import api from "@/api/api";
import { DashboardConfig } from "@/types/dashboardConfig";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";

class DashboardConfigDataAccess {
  async getConfig(): Promise<DataResponse<DashboardConfig> | undefined> {
    try {
      const response = await api.get('/dashboardconfig');
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getConfigWithAccessToken(token: string): Promise<DataResponse<DashboardConfig> | undefined> {
    try {
      const response = await api.get(`/dashboardconfig/access?accessToken=${token}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async updateConfig(config: DashboardConfig): Promise<DataResponse<DashboardConfig> | undefined> {
    try {
        const response = await api.post('/dashboardconfig/update', config, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
            return error.response?.data;
        }
    }
  }
}

const dashboardConfigDataAccess = new DashboardConfigDataAccess();
export default dashboardConfigDataAccess;