import gameDataAccess from "@/api/gameDataAccess";
import { Game, GameGroup, GameTag } from "@/types/game";
import { store } from "@/store";
import { useToastService } from "@/utils/ToastService";
import { DataResponse } from "@/types/types";
import { ref } from "vue";

export function useGames() {
  const toast = useToastService();
  const allGamesByGroup = ref<GameGroup[]>([]);

  async function populateGames() {
    const response = await gameDataAccess.getGames();
    if (response != undefined) {
      allGamesByGroup.value = response.data;
      return copyObject(allGamesByGroup.value);
    }
  }

  async function populateTags() {
    let response = await gameDataAccess.GetTags();
    if (response != null) {
      return response.data.map(({ id, tagName }: {id: number; tagName: string}) => ({
        tagId: id,
        tagName: tagName,
      }));
    }
    else return []
  }

  function searchGames(allGamesByGroup: GameGroup[], gameSearchString?: string) {
    if (gameSearchString == null) {
      return allGamesByGroup;
    }
    const searchText = gameSearchString.trim().toLowerCase();
    if (!searchText) {
      return allGamesByGroup;
    }
  
    let filtered = applySearchFilterOnGames(
      copyObject(allGamesByGroup),
      searchText
    );
  
    return filtered;
  }

  function filterGamesByTagIds(allGamesByGroup: GameGroup[], filterTagIds: number[]) {
    if (filterTagIds.length == 0) {
      return allGamesByGroup;
    } else {
      return applyTagFilterOnGames(copyObject(allGamesByGroup), filterTagIds);
    }
  }

  async function deleteGame(game: Game) {
    let response: DataResponse<null> | undefined;
    let summary = "Couldn't Delete Game";
    let detail = "Something went wrong.";

    if (store.state.userInfo.roles.map((x) => x.name).includes("Admin")) {
      response = await gameDataAccess.deleteGame(game.id);
    } else if (store.state.userInfo.id == game.user.id) {
      response = await gameDataAccess.deleteMyGame(game.id);
    }

    if (!response) {
      toast.addErrorToast(summary, detail);
      return false;
    } else {
      if (response.success) {
        summary = "Game Deleted Successfully";
        detail = `${game.gameTitle} has been deleted.`;
        toast.addSuccessToast(summary, detail);
        return true;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
        return false;
      }
    }
  }

  return {
    allGamesByGroup,
    populateGames,
    populateTags,
    searchGames,
    filterGamesByTagIds,
    deleteGame,
  };
}

function applySearchFilterOnGames(
  allGamesByGroup: GameGroup[],
  searchText: string
) {
  let newFilteredGames = Array(0);

  allGamesByGroup.forEach((group: { games: any[] }) => {
    let filteredGamesForGroup = getSearchedGamesForGroup(group, searchText);
    group.games = filteredGamesForGroup;
    newFilteredGames.push(group);
  });
  return newFilteredGames;
}

function getSearchedGamesForGroup(group: any, searchText: string) {
  let searchedGames = Array(0);
  group.games.forEach(
    (game: {
      gameTitle: string;
      summary: string;
      user: { firstName: string; lastName: string };
    }) => {
      if (
        game.gameTitle.toLowerCase().includes(searchText) ||
        game.user.firstName.toLowerCase().includes(searchText) ||
        game.user.lastName.toLowerCase().includes(searchText)
      ) {
        searchedGames.push(game);
      }
    }
  );

  return searchedGames;
}

function applyTagFilterOnGames(allGamesByGroup: GameGroup[], tagIds: number[]) {
  let newFilteredGames = Array(0);

  allGamesByGroup.forEach((group: { games: any[] }) => {
    let filteredGamesForGroup = getFilteredGamesForGroup(group, tagIds);
    group.games = filteredGamesForGroup;
    newFilteredGames.push(group);
  });
  return newFilteredGames;
}

function getFilteredGamesForGroup(group: any, tagIds: number[]) {
  let matchedGames = Array(0);
  group.games.forEach((game: { tags: any[] }) => {
    let gameTagIds = game.tags.map((gameTag) => gameTag.id);
    if (isSublist(gameTagIds, tagIds)) {
      matchedGames.push(game);
    }
  });

  return matchedGames;
}

function isSublist(outerList: number[], innerList: number[]) {
  return innerList.every((innerItem) => {
    return outerList.includes(innerItem);
  });
}

function copyObject(obj: any) {
  return JSON.parse(JSON.stringify(obj)) as typeof obj;
}