import lunchDataAccess from "@/api/lunchDataAccess";
import { Lunch } from "@/types/lunch"
import { ref } from "vue"
import { useToastService } from "@/utils/ToastService";
import { DataResponse } from "@/types/types";


export function useLunches() {
    const lunches = ref<Lunch[]>([]);
    const toast = useToastService();
    const lunchesLoading = ref(false);

    async function populateLunches() {
        lunchesLoading.value = true;

        let response: DataResponse<Lunch[]> | undefined;
        if(window.location.href.includes('accessToken')) {
            const urlParams = new URLSearchParams(window.location.search);
            let token = urlParams.get("accessToken");

            if(token == null)
            {
                token = "";
            }

            response = await lunchDataAccess.getLunchesWithAccessToken(token);
        }
        else {
            response = await lunchDataAccess.getLunches();
        }

        if(response != undefined) {
            lunches.value = response.data;
        }
        lunchesLoading.value = false;
    }

    async function createLunch(uploadForm: FormData) {
        try {
            lunchesLoading.value = true;
            
            const response = await lunchDataAccess.createLunch(uploadForm);

            if (response) {
                toast.addSuccessToast('Lunch uploaded successfully', '');
                await populateLunches();
            } else {
                toast.addErrorToast('Failed to upload lunch', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to upload lunch', '');
        } finally {
            lunchesLoading.value = false;
        }
    }

    async function deleteLunch(id: number) {
        try {
            lunchesLoading.value = true;
            const response = await lunchDataAccess.deleteLunch(id);
            if (response) {
                toast.addSuccessToast('Lunch deleted successfully', '');
                await populateLunches();
            } else {
                toast.addErrorToast('Failed to delete lunch', response!.message);
            }
        } catch (error) {
            toast.addErrorToast('Failed to delete lunch', '');
        } finally {
            lunchesLoading.value = false;
        }
    }

    init();
    async function init() {
        populateLunches();
    }

    return {
        lunches,
        lunchesLoading,
        populateLunches,
        createLunch,
        deleteLunch
    };
}

function copyObject(obj: any) {
    return JSON.parse(JSON.stringify(obj)) as typeof obj;
}