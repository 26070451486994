import axios from "axios";
import auth from "@/auth";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    if (window.location.href.includes('accessToken')) {
      return config;
    }

    const token = await auth.getApiTokenRedirect();
    if (!token) throw ReferenceError("Access token not found.");
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
