import controlPanelAccessTokensDataAccess from "@/api/controlPanelAccessTokensDataAccess";
import { useToastService } from "@/utils/ToastService";

export function useControlPanelAccessTokens() {
  const toast = useToastService();

  async function createToken(description: string){
    let response = await controlPanelAccessTokensDataAccess.createToken(description);

    if(response){
      return response.data;
    }
    else{
      toast.addErrorToast("Couldn't Create Access Token", "");
    }
  }

  async function getTokens() {
    let response = await controlPanelAccessTokensDataAccess.getTokens();

    if (response) {
      return response.data;
    } else {
      toast.addErrorToast("Couldn't Load Access Tokens", "");
    }
  }

  async function deleteToken(id: number) {
    let response = await controlPanelAccessTokensDataAccess.deleteToken(id);

    if(response){
      return response.data;
    }
    else{
      toast.addErrorToast("Couldn't Delete Access Token", "");
    }
  }

  return{
    createToken,
    getTokens,
    deleteToken
  }
}
