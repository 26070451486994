import { ref } from "vue";
import { User } from "@/types/types";
import userDataAccess from "@/api/userDataAccess";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useConfirm } from "primevue/useconfirm";
import { useToastService } from "@/utils/ToastService";

export function useManageUsers() {
  const toast = useToastService();
  const users = ref<User[]>([]);
  const defaultUser: User = {
    id: 0,
    graphId: "",
    firstName: "",
    lastName: "",
    email: "",
    positions: [],
    roles: [],
    skillLevels: [],
    active: false,
    deletedAt: undefined
  };
  const loading = ref(true);
  const selectedUsers = ref<User[]>([]);
  const condenseUserInfo = ref<Boolean>();
  const confirm = useConfirm();
  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    lastName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    active: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  populateUsers();
  /* Initialize Function */
  async function populateUsers() {
    const response = await userDataAccess.getUsers();
    users.value = [];
    if (response != undefined) {
      response.data.forEach((user: User) => {
        if(user.deletedAt === null) {
          users.value.push(user);
        }
      });
    }
    loading.value = false;
  }

  /* Component Functions */
  const clearFilters = () => {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      lastName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      active: { value: null, matchMode: FilterMatchMode.EQUALS },
    };
  };

  async function showDeletePopUp(event: any, user: User) {
    const name = user.firstName + " " + user.lastName;
    confirm.require({
      target: event.currentTarget,
      message: `Are you sure you want to delete ${name}?`,
      icon: "pi pi-info-cirle",
      acceptClass: "p-button-danger",
      async accept() {
        user.deletedAt = new Date();
        var response = await userDataAccess.updateUser(user);
        if(response?.success) {
          toast.addSuccessToast("User Deleted Sucessfuly", `${user.firstName} ${user.lastName} was deleted.`);
        }
        else {
          toast.addErrorToast("User Deleted Failed", `${user.firstName} ${user.lastName} couldn't be deleted.`);
        }
        populateUsers();
      },
    });
  }

  function resize() {
    const widthToCondense = 1350;
    const mobileWidth = 390;
    if (window.innerWidth <= widthToCondense) condenseUserInfo.value = true;
    else condenseUserInfo.value = false;
    if (window.innerWidth < mobileWidth) {
      document
        .getElementById("manage-users-table")
        ?.classList.add("p-datatable-sm");
    } else {
      document
        .getElementById("manage-users-table")
        ?.classList.remove("p-datatable-sm");
    }
  }

  function updateFilter(filterModel: null) {
    filters.value.active.value = filterModel;
  }

  return {
    toast,
    condenseUserInfo,
    filters,
    selectedUsers,
    defaultUser,
    users,
    clearFilters,
    showDeletePopUp,
    resize,
    updateFilter,
    loading,
  };
}
