import api from "@/api/api";
import { Joke } from "@/types/joke";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";

class JokeDataAccess {
    async getDailyJoke() {
      try {
        let response;
        if(window.location.href.includes('accessToken')) {
          const urlParams = new URLSearchParams(window.location.search);
          let token = urlParams.get("accessToken");

          if(token == null)
          {
              token = "";
          }
          response = await api.get(`/joke/daily/access?accessToken=${token}`);
        }
        else {
          response = await api.get('/joke/daily');
        }

        if(response != null)
          return response.data;
        else
          return undefined;

      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          return error.response?.data;
        }
      }
    }

    async fetchRandomDadJoke(): Promise<string | null> {
      const url = 'https://icanhazdadjoke.com/';
      const options = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'User-Agent': 'Omnitech Intern Project (https://www.omnitech-inc.com/)'
        }
      };
    
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const joke: string = data.joke;
        return joke;
      } catch (error) {
        console.error('Error fetching the dad joke:', error);
        return null;
      }
    }
}
  
const jokeDataAccess = new JokeDataAccess();
export default jokeDataAccess;