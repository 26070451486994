import {
  Skill,
  SkillCategory,
  SkillLevel,
  Response,
  DataResponse,
} from "../types/types";
import api from "./api";
import { AxiosError } from "axios";

class SkillDataAccess {
  async getSkillCategories(): Promise<
    DataResponse<SkillCategory[]> | undefined
  > {
    try {
      const response = await api.get("Skills/Categories");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async createCategory(
    categoryName: string
  ): Promise<DataResponse<SkillCategory> | undefined> {
    try {
      const response = await api.post("Skills/Categories", {
        name: categoryName,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async editCategory(
    id: number,
    newName: string
  ): Promise<DataResponse<SkillCategory> | undefined> {
    try {
      const response = await api.put("Skills/Categories", {
        id,
        name: newName,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteCategory(id: number): Promise<Response | undefined> {
    try {
      const response = await api.delete("Skills/Categories", { data: { id } });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getSkills(): Promise<DataResponse<Skill[]> | undefined> {
    try {
      const response = await api.get("Skills");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async createSkill(skill: Skill): Promise<DataResponse<Skill> | undefined> {
    try {
      const response = await api.post("Skills", skill);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteSkill(id: number): Promise<Response | undefined> {
    try {
      const response = await api.delete("Skills", { data: { id } });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async editSkill(skill: Skill): Promise<DataResponse<Skill> | undefined> {
    try {
      const response = await api.put("Skills", skill);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async mergeSkills(
    skills: Skill[],
    mergedSkill: Skill
  ): Promise<DataResponse<Skill> | undefined> {
    try {
      const response = await api.put("Skills/Merge", {
        skills,
        mergedSkill,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getMySkillLevels(): Promise<DataResponse<SkillLevel[]> | undefined> {
    try {
      const response = await api.get("SkillLevels/me");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getEngineerSkills(
    userID: number
  ): Promise<DataResponse<SkillLevel[]> | undefined> {
    try {
      const response = await api.get("SkillLevels/" + userID);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }
  async addSkillLevelToMe(
    skill: SkillLevel
  ): Promise<DataResponse<SkillLevel> | undefined> {
    try {
      const response = await api.post("SkillLevels/me", skill);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async addSkillLevelsToMe(
    skills: SkillLevel[]
  ): Promise<DataResponse<SkillLevel[]> | undefined> {
    try {
      const response = await api.post("SkillLevels/skills/me/", skills);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteSkillLevel(
    skillLevel: SkillLevel
  ): Promise<Response | undefined> {
    try {
      const response = await api.delete("SkillLevels/me", {
        data: skillLevel,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async editSkillLevel(
    skillLevel: SkillLevel
  ): Promise<DataResponse<SkillLevel> | undefined> {
    try {
      const response = await api.put("SkillLevels/me", skillLevel);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async editSkillLevels(
    skillLevels: SkillLevel[]
  ): Promise<DataResponse<SkillLevel[]> | undefined> {
    try {
      const response = await api.put("/SkillLevels/skills/me", skillLevels);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getStaleSkills(): Promise<DataResponse<SkillLevel[]> | undefined> {
    try {
      const response = await api.get("/SkillLevels/Stale/me");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getNewSkills(
    count: number
  ): Promise<DataResponse<Skill[]> | undefined> {
    try {
      const response = await api.get(`Skills/recent/${count}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getPopularSkills(
    count: number
  ): Promise<DataResponse<Skill[]> | undefined> {
    try {
      const response = await api.get(`Skills/popular/${count}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }
}

const skillDataAccess = new SkillDataAccess();

export default skillDataAccess;
