import skillDataAccess from "@/api/skillDataAccess";
import { Skill } from "@/types/types";
import { ref } from "vue";
import { useToastService } from "@/utils/ToastService";

export function usePopularSkills(count = 5) {
  const popularSkills = ref<Skill[]>([]);
  const toast = useToastService();

  async function update() {
    await getPopularSkills();
  }

  async function init() {
    await getPopularSkills();
  }

  async function getPopularSkills() {
    const summary = "Couldn't Get Popular Skills";
    let detail = "Something went wrong.";
    const response = await skillDataAccess.getPopularSkills(count);

    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        popularSkills.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  init();

  return { popularSkills, update };
}
