import api from "@/api/api";
import { Image, ImageUpload } from "@/types/image";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";

class SlideshowDataAccess {
  async getImages(): Promise<DataResponse<Image[]> | undefined> {
    try {
      const response = await api.get('/slideshow');
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getImagesWithAccessToken(token: string): Promise<DataResponse<Image[]> | undefined> {
    try {
      const response = await api.get(`/slideshow/access?accessToken=${token}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getFilteredImages(): Promise<DataResponse<Image[]> | undefined> {
    try {
      const response = await api.get('/slideshow/filtered');
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getFilteredImagesWithAccessToken(token: string): Promise<DataResponse<Image[]> | undefined> {
    try {
      const response = await api.get(`/slideshow/filtered/access?accessToken=${token}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async uploadImage(imageFile: FormData): Promise<DataResponse<Image> | undefined> {
    try {
        const response = await api.post('/slideshow/upload', imageFile, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
            return error.response?.data;
        }
    }
  }

  async deleteImage(imageId: number): Promise<DataResponse<null> | undefined> {
    try {
      const resposne = await api.delete(`slideshow/delete/${imageId}`);
      return resposne.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }
}

const slideshowDataAccess = new SlideshowDataAccess();
export default slideshowDataAccess;