import axios from "axios";
import auth from "@/auth";
import api from "@/api/api";
import {
  User,
  Engineer,
  Position,
  Response,
  DataResponse,
} from "@/types/types";
import { AxiosError } from "axios";

class UserDataAccess {
  async getUsers(): Promise<DataResponse<User[]> | undefined> {
    try {
      const response = await api.get("Users");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getUser(id: number): Promise<DataResponse<User> | undefined> {
    try {
      const response = await api.get(`Users/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getPositions(): Promise<DataResponse<Position[]> | undefined> {
    try {
      const response = await api.get("Positions");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getEngineers(): Promise<DataResponse<Engineer[]> | undefined> {
    try {
      const response = await api.get("/users/engineers");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getUserInfo(): Promise<DataResponse<User> | undefined> {
    try {
      const response = await api.get("/Users/me");
      return response.data;
    } catch (error: any) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getUserInfoAdmin(
    userID: number
  ): Promise<DataResponse<User> | undefined> {
    try {
      const response = await api.get(`/users/${userID}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getUserPositions(): Promise<DataResponse<Position[]> | undefined> {
    try {
      const response = await api.get("/positions/me");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async getUserProfileImage(): Promise<string | undefined> {
    return auth.getProfileTokenRedirect()?.then(async (token) => {
      try {
        const response = await axios.get(
          "https://graph.microsoft.com/v1.0/me/photos/504x504/$value",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );
        return window.URL.createObjectURL(response.data);
      } catch (error) {
        console.error(error);
      }
    });
  }

  async getUserProfileImageById(email: string): Promise<string | undefined> {
    return auth.getProfileTokenRedirect()?.then(async (token) => {
      try {
        const response = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${email}/photo/$value`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );
        return window.URL.createObjectURL(response.data);
      } catch (error) {
        console.error(error);
      }
    });
  }

  async setUserPositions(
    newPositions: Position[]
  ): Promise<DataResponse<Position[]> | undefined> {
    try {
      const response = await api.put("/positions/me", newPositions);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async updateUser(user: User): Promise<DataResponse<User> | undefined> {
    try {
      const response = await api.put("/users/", user);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }

  async deleteUser(userID: number): Promise<Response | undefined> {
    try {
      const response = await api.delete(`/users/${userID}`);
      return response.data;
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        return error.response?.data;
      }
    }
  }
}

const userDataAccess = new UserDataAccess();

export default userDataAccess;
