import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import { store, key } from "@/store";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Ripple from "primevue/ripple";
import ConfirmationService from "primevue/confirmationservice";
import Chart from "primevue/chart";
import Carousel from "primevue/carousel";
import Tooltip from "primevue/tooltip";
import Chip from "primevue/chip";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";
import Chips from "primevue/chips";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Slider from 'primevue/slider';
import Calendar from 'primevue/calendar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';


const app = createApp(App);

app.use(store, key);
app.use(router);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(ConfirmationService);

app.directive("ripple", Ripple);
app.directive("tooltip", Tooltip);
// eslint-disable-next-line
app.component("Chart", Chart);
app.component("Carousel", Carousel);
app.component("Dialog", Dialog);
app.component("Chip", Chip);
app.component("Chips", Chips);
app.component("FileUpload", FileUpload);
app.component("InputText", InputText);
app.component("InputSwitch", InputSwitch);
app.component("Checkbox", Checkbox);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Slider", Slider);
app.component("InputNumber", InputNumber);
app.component("Calendar", Calendar);

app.component("Accordian", Accordion);
app.component("AccordianTab", AccordionTab);

app.mount("#app");
