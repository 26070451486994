import { createWebHistory, createRouter } from "vue-router";
import WelcomePage from "@/views/WelcomePage.vue";
import AccountPage from "@/views/AccountPage.vue";

import SkillsDashboard from "@/views/SkillsDashboard.vue";
import MySkills from "@/views/MySkills.vue";
import SkillsSurvey from "@/views/SkillsSurvey.vue";

import EngineerSearch from "@/views/EngineerSearch.vue";
import ViewEngineer from "@/views/ViewEngineer.vue";

import ManageUsers from "@/views/ManageUsers.vue";
import ManageUsersEditUser from "@/views/ManageUsersEditUser.vue";
import ManageUsersViewUser from "@/views/ManageUsersViewUser.vue";
import AdminSkills from "@/views/AdminSkills.vue";

import GamesPage from "@/views/GamesPage.vue";
import GameUpload from "@/views/GameUpload.vue";
import GameUpdate from "@/views/GameUpdate.vue";

import ViewSeatingCharts from "@/views/seating/ViewSeatingChart.vue";
import EditSeatingCharts from "@/views/seating/EditSeatingChart.vue";
import EditFloorPlans from "@/views/seating/EditFloorPlans.vue";
import SeatingChartEditor from "@/views/seating/SeatingChartEditor.vue";

import OmnitechDashboard from "@/views/OmnitechDashboard.vue";
import OmnitechDashboardControlPanel from "@/views/OmnitechDashboardControlPanel.vue";

import { useStore } from "@/store";

const routes = [
  {
    path: "/",
    redirect: "/skills/dashboard",
    meta: { requiresAdmin: false },
  },
  {
    path: "/home",
    redirect: "/",
    meta: { requiresAdmin: false },
  },
  {
    path: "/redirect",
    redirect: "/",
    meta: { requiresAdmin: false },
  },
  {
    path: "/logout",
    redirect: "/",
    meta: { requiresAdmin: false },
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: WelcomePage,
    meta: {
        requiresAdmin: false,
        hideNav: true 
    },
  },
  {
    path: "/account",
    name: "Account",
    component: AccountPage,
    meta: { requiresAdmin: false },
  },
  {
    path: "/skills/dashboard",
    name: "Skills Dashboard",
    component: SkillsDashboard,
    meta: { requiresAdmin: false },
  },
  {
    path: "/my-skills",
    name: "My Skills",
    component: MySkills,
    meta: { requiresAdmin: false },
  },
  {
    path: "/myskills",
    redirect: "/my-skills",
    meta: { requiresAdmin: false },
  },
  {
    path: "/my-skills/survey",
    name: "Skills Survey",
    component: SkillsSurvey,
    meta: { requiresAdmin: false },
  },
  {
    path: "/myskills/survey",
    redirect: "/my-skills/survey",
    meta: { requiresAdmin: false },
  },
  {
    path: "/engineers/Search",
    name: "Search Engineers",
    component: EngineerSearch,
    meta: { requiresAdmin: false },
  },
  {
    path: "/Engineers/:userID",
    name: "View Engineer",
    component: ViewEngineer,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/admin/users",
    name: "Manage Users",
    component: ManageUsers,
    meta: { requiresAdmin: false },
  },
  {
    path: "/admin/users/view/:userID",
    name: "Admin View User",
    component: ManageUsersViewUser,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/admin/users/edit/:userID",
    name: "Admin Edit User",
    component: ManageUsersEditUser,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/admin/skills",
    name: "Admin Skills",
    component: AdminSkills,
    meta: { requiresAdmin: false },
  },
  {
    path: "/games",
    name: "Games",
    component: GamesPage,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/games/upload",
    name: "Upload Game",
    component: GameUpload,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/games/update/:id",
    name: "Update Game",
    component: GameUpdate,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/seating/view",
    name: "View Seating Charts",
    component: ViewSeatingCharts,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/seating/edit-seating",
    name: "Edit Seating Charts",
    component: EditSeatingCharts,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/seating/edit-floorplans",
    name: "Edit Floor Plans",
    component: EditFloorPlans,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/seating/edit-seating/:id",
    name: "Edit Specific Seating Chart",
    component: SeatingChartEditor,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/omnitech-dashboard",
    name: "Omnitech Dashboard",
    component: OmnitechDashboard,
    props: true,
    meta: { requiresAdmin: false },
  },
  {
    path: "/omnitech-dashboard-control-panel",
    name: "Omnitech Dashboard Control Panel",
    component: OmnitechDashboardControlPanel,
    props: true,
    meta: { requiresAdmin: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
