import seatingChartDataAccess from "@/api/SeatingChartDataAccess";
import { useToastService } from "@/utils/ToastService";
import { ref } from "vue";
import { Floorplan, Rectangle, UploadFloorplanDTO } from "@/types/seatingChart";

export const useFloorplans = () => {
  const INITIAL_CANVAS_WIDTH: number = 3000 * 3;
  const INITIAL_CANVAS_HEIGHT: number = 2000 * 3;
  const floorplans = ref<Floorplan[]>([]);
  const showCreateFloorplanPopUp = ref<boolean>(false);
  const showSaveAsFloorplanPopUp = ref<boolean>(false);
  const publishedFloorplans = ref<Floorplan[]>([]);
  const floorplanLoading = ref<boolean>(false);
  const defaultFloorplan: Floorplan = {
    id: 0,
    isPublished: false,
    sizeX: 0,
    sizeY: 0,
    userId: 0,
    name: "",
    location: {
      id: 0,
      name: "",
    },
    rectangles: [],
  };
  const defaultRectangle: Rectangle = {
    id: -1,
    floorplanId: -1,
    isActive: false,
    label: "",
    startX: -1,
    startY: -1,
    endX: -1,
    endY: -1, 
  }
  const toast = useToastService();

  async function populateFloorPlans() {
    floorplanLoading.value = true;

    const response = await seatingChartDataAccess.GetFloorPlans();
    if (response == null) return;
    floorplans.value = response.data.map(
      ({
        id,
        isPublished,
        sizeX,
        sizeY,
        userId,
        name,
        location,
        rectangles,
      }) => ({
        id: id,
        isPublished: isPublished,
        sizeX: sizeX,
        sizeY: sizeY,
        name: name,
        userId: userId,
        location: location,
        rectangles: rectangles,
      })
    );
    publishedFloorplans.value = floorplans.value.filter(
      (fp) => fp.isPublished == true
    );

    floorplanLoading.value = false;
  }

  function toggleShowCreateFloorplanPopUp() {
    showCreateFloorplanPopUp.value = !showCreateFloorplanPopUp.value;
  }

  function toggleShowSaveAsFloorplanPopUp() {
    showSaveAsFloorplanPopUp.value = !showSaveAsFloorplanPopUp.value;
  }

  function clearFloorplanRectangles(floorplan: Floorplan) {
    floorplan.rectangles = [];
  }

  function mapFloorplanToUploadDTO(floorplan: Floorplan) {
    const uploadDTO: UploadFloorplanDTO = {
      name: floorplan.name,
      isPublished: floorplan.isPublished,
      sizeX: floorplan.sizeX,
      sizeY: floorplan.sizeY,
      userId: floorplan.userId,
      locationId: floorplan.location.id,
      rectangles: floorplan.rectangles,
    };

    return uploadDTO;
  }

  async function uploadFloorplan(floorplan: Floorplan) {
    floorplanLoading.value = true;
    const floorplanToUpload: UploadFloorplanDTO =
      mapFloorplanToUploadDTO(floorplan);
    let newFloorplan: Floorplan = defaultFloorplan;

    const response = await seatingChartDataAccess.uploadFloorPlan(
      floorplanToUpload
    );
    if (response?.success) {
      newFloorplan = response.data;
      floorplans.value.push(newFloorplan);

      toast.addSuccessToast(
        `${floorplan.name} Uploaded Successfully`,
        response.message
      );
    } else {
      toast.addErrorToast(
        `Unable to Upload ${floorplan.name}`,
        response!.message
      );
    }

    floorplanLoading.value = false;
    return newFloorplan;
  }

  async function deleteFloorplan(id: number) {
    floorplanLoading.value = true;
    const response = await seatingChartDataAccess.DeleteFloorPlan(id);
    if (response?.success) {
      toast.addSuccessToast(
        `Floor plan Deleted Successfully`,
        response.message
      );
      floorplans.value = floorplans.value.filter((fp) => fp.id != id);
    } else {
      toast.addErrorToast(`Floor plan Could Not Be Deleted`, "");
    }
    floorplanLoading.value = false;
  }

  init();
  async function init() {
    await populateFloorPlans();
  }

  return {
    INITIAL_CANVAS_WIDTH,
    INITIAL_CANVAS_HEIGHT,
    defaultFloorplan,
    defaultRectangle,
    floorplans,
    publishedFloorplans,
    showCreateFloorplanPopUp,
    showSaveAsFloorplanPopUp,
    toggleShowCreateFloorplanPopUp,
    toggleShowSaveAsFloorplanPopUp,
    populateFloorPlans,
    deleteFloorplan,
    clearFloorplanRectangles,
    uploadFloorplan,
    floorplanLoading,
  };
};
