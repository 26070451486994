<template>
    <div class="dashboard-block component-wrapper dashboard-socials">
      <div class="header">
        <div class="insta-prof-border">
          <img 
            class="insta-prof" 
            src="@/assets/iNSPiRE.png" 
            @click="getProf(0)"
          />
        </div>
        <div class="insta-prof-border">
          <img 
            class="insta-prof" 
            src="@/assets/Omnitech.png" 
            @click="getProf(1)"
          />
        </div>
      </div>
      <div class="body">
        <div id="profile-0">
          <div  
            class="elfsight-app-fb8a6047-504b-4892-b0c1-8f1a02e6aed3"
            data-elfsight-app-lazy
          ></div>
        </div>
        <div id="profile-1">
          <div 
            class="elfsight-app-62a8ae04-859f-4000-9cc8-740605e0c429"
            data-elfsight-app-lazy
          ></div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

const currProf = ref();
const profData = ref(["elfsight-app-fb8a6047-504b-4892-b0c1-8f1a02e6aed3", "elfsight-app-62a8ae04-859f-4000-9cc8-740605e0c429"]);
var i = 0;

onMounted(() => {
  const script = document.createElement('script');
  script.src = 'https://static.elfsight.com/platform/platform.js';
  script.defer = true;
  script.dataset.useServiceCore = true;

  document.body.appendChild(script);

  for(i = 0; i < profData.value.length; i++) {
    document.getElementById(`profile-${i}`).style.display = "none";
  }
  document.getElementById(`profile-0`).style.display = "block";
});

function getProf(id) {
  for(i = 0; i < profData.value.length; i++) {
    document.getElementById(`profile-${i}`).style.display = "none";
  }
  document.getElementById(`profile-${id}`).style.display = "block";
}

</script>

<style lang="scss" scoped>
.dashboard-block {
  height: 100%;
  width: 100%;
}

.component-wrapper {
  border-radius: 20px;
  border: 1px solid #543395;
  padding: 0;
  overflow: hidden;
}

.header {
  margin: 10px 10px 0 10px;
  height: 8%;
  display: flex;
  flex-direction: row;
}

.insta-prof-border {
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: linear-gradient(132.32deg, #FF0808 9.68%, #F7C309 109.57%);
  padding: 1.5px;
  margin: 0 5px 0 5px;
  overflow: hidden;

  :hover {
    padding: 2px;
  }
}

.insta-prof {
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
}

.body {
  align-content: center;
  justify-content: center;
}

::v-deep(.eapps-instagram-feed-container) {
  scale: 0.90;
  height: 0px;
  padding: 5px;
}

div[id*="eapps-instagram-feed"] {
  width: unset !important;
  height: unset !important;
}  

.eapps-instagram-feed-posts-item-template-tile .eapps-instagram-feed-posts-item-image {
  aspect-ratio: 4/3;
}

@media only screen and (min-width: 1610px) {
  ::v-deep(.eapps-instagram-feed-container) {
    scale: 1;
    height: 0px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .insta-prof-border {
    height: 65px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: linear-gradient(132.32deg, #FF0808 9.68%, #F7C309 109.57%);
    padding: 1.5px;
    margin: 0 5px 0 5px;
    overflow: hidden;

    :hover {
      padding: 2px;
    }
  }

  .header {
    margin: 10px 10px 0 30px;
    height: 20%;
    display: flex;
    flex-direction: row;
  }

  ::v-deep(.eapps-instagram-feed-container) {
    scale: 0.85;
    height: 0px;
    padding: 0;
  }
}

</style>
