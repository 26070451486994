import api from "@/api/api";
import { DataResponse } from "@/types/types";
import { AxiosError } from "axios";
import { Lunch } from "@/types/lunch";
import controlPanelDataAccess from "./controlPanelAccessTokensDataAccess";

class LunchDataAccess {
    async getLunches(): Promise<DataResponse<Lunch[]> | undefined> {
        try {
          const response = await api.get("Lunch");
          return response.data;
        } catch (error) {
          console.error(error);
          if (error instanceof AxiosError) {
            return error.response?.data;
          }
        }
    }

    async getLunchesWithAccessToken(token: string): Promise<DataResponse<Lunch[]> | undefined> {
      try {
        const response = await api.get(`Lunch/access?accessToken=${token}`);
        return response.data;
      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          return error.response?.data;
        }
      }
    }

    async createLunch(
      uploadForm: FormData
    ): Promise<DataResponse<Lunch> | undefined> {
      try {
        const response = await api.post("Lunch", uploadForm, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return response.data;
      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          return error.response?.data;
        }
      }
    }

    async deleteLunch(id: number): Promise<DataResponse<null> | undefined> {
      try {
        const response = await api.delete(`lunch/${id}`);
        return response.data;
      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          return error.response?.data;
        }
      }
    }
}

const lunchDataAccess = new LunchDataAccess();

export default lunchDataAccess;
