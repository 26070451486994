import { Skill } from "@/types/types";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { ref } from "vue";

export function useAdminSkillsTable() {
  const selectedSkills = ref<Skill[]>([]);
  const skill = ref<Skill>({
    id: 0,
    name: "",
    category: {
      id: 0,
      name: "",
      createdTimestamp: new Date(),
      modifiedTimestamp: new Date(),
    },
    usersWithSkill: [],
    createdTimestamp: new Date(),
    modifiedTimestamp: new Date(),
  });
  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    skillName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    categoryName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  function clearFilters() {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      skillName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      categoryName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    };
  }

  return {
    filters,
    selectedSkills,
    skill,
    clearFilters,
  };
}
