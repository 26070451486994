import announcementDataAccess from "@/api/announcementDataAccess";
import { Announcement } from "@/types/announcement";
import { DataResponse } from "@/types/types";
import { useToastService } from "@/utils/ToastService";
import { ref } from "vue";

export function useAnnouncements() {
    const announcementsLoading = ref<boolean>(false)
    const toast = useToastService();
    const announcements = ref<Announcement[]>([{
        id: 0,
        createdTimestamp: new Date(),
        modifiedTimestamp: new Date(),
        expiresAt: new Date(),
        message: "Loading Announcements..."
    }]);

    async function populateAnnouncements() {
      announcementsLoading.value = true;

      let response: DataResponse<Announcement[]> | undefined;
      if(window.location.href.includes('accessToken')) {
          const urlParams = new URLSearchParams(window.location.search);
          let token = urlParams.get("accessToken");

          if(token == null)
          {
              token = "";
          }

          response = await announcementDataAccess.getAnnouncementsWithAccessToken(token);
      }
      else {
        response = await announcementDataAccess.getAnnouncements();
      }

      if(response != undefined) {
          announcements.value = response.data;
      } else {
        toast.addErrorToast("Error Loading Announcements", "Couldn't Load the Announcements")
      }

      announcementsLoading.value = false;
  }

    async function deleteAnnouncement(id: number) {
        announcementsLoading.value = true;

        await announcementDataAccess.deleteAnnouncement(id);
        populateAnnouncements();

        announcementsLoading.value = false;
    }

    async function createAnnouncement(announcement: Announcement) {
        announcementsLoading.value = true;
        
        await announcementDataAccess.createAnnouncement(announcement);
        populateAnnouncements();

        announcementsLoading.value = false;
    }
  
    function init() {
        populateAnnouncements();
    }

    init();
    return {
        announcements,
        announcementsLoading,
        createAnnouncement,
        populateAnnouncements,
        deleteAnnouncement
    };
  }