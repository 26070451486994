import { SkillLevel, Skill, SkillCategory, DataResponse } from "@/types/types";
import skillDataAccess from "@/api/skillDataAccess";
import { ref, computed } from "vue";
import { useToastService } from "@/utils/ToastService";

export function useAddSkillsAndLevels() {
  const allSkills = ref<Skill[]>([]);
  const categories = ref<SkillCategory[]>([]);
  const categoryNames = computed(() => {
    return categories.value.map((x) => x.name);
  });
  const ownedSkills = ref<Skill[]>([]);
  const loading = ref(true);
  const newSkills = computed(() => {
    return allSkills.value.filter((x: Skill) => {
      return (
        ownedSkills.value.filter((y) => {
          return y.id == x.id;
        }).length == 0
      );
    });
  });
  const newSkillNames = computed(() => {
    return newSkills.value.map((x) => x.name).sort();
  });
  const showCreateSkillPopup = ref<boolean>(false);
  const skillLevelToAdd = ref<SkillLevel>({
    user: {
      id: 0,
      graphId: "",
      firstName: "",
      lastName: "",
      email: "",
      positions: [],
      roles: [],
      active: true,
      deletedAt: undefined,
      skillLevels: [],
    },
    skill: {
      id: 0,
      name: "",
      category: {
        id: 0,
        name: "",
        createdTimestamp: new Date(),
        modifiedTimestamp: new Date(),
      },
      usersWithSkill: [],
      createdTimestamp: new Date(),
      modifiedTimestamp: new Date(),
    },
    level: 1,
    createdTimestamp: new Date(),
    modifiedTimestamp: new Date(),
  });

  const toast = useToastService();

  async function createSkill(
    skill: Skill
  ): Promise<DataResponse<Skill> | undefined> {
    const categoryId = categories.value.find(
      (c) => c.name == skill.category.name
    )?.id;
    if (categoryId != undefined) skill.category.id = categoryId;

    const check = checkCreateSkill(skill);
    if (!check) return;

    let summary = "Couldn't Create Skill";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.createSkill(skill);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        allSkills.value.push(response.data);

        summary = "Skill Created";
        detail = `${skill.name} has been created.`;
        toast.addSuccessToast(summary, detail);

        toggleCreatePopup();

        return response;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function checkCreateSkill(skill: Skill) {
    const summary = "Couldn't Create Skill";
    let detail = "Something went wrong.";

    if (skill.name === "") {
      detail = "Please enter a skill name.";
      toast.addErrorToast(summary, detail);
      return false;
    } else if (skill.category.name == "") {
      detail = "Please select a category.";
      toast.addErrorToast(summary, detail);
      return false;
    }
    if (skill.category.id == undefined) {
      detail = `Category: ${skill.category.name} not found`;
      toast.addErrorToast(summary, detail);
      return;
    }
    return true;
  }

  async function addSkillLevel(
    skillLevelToAdd: SkillLevel
  ): Promise<DataResponse<SkillLevel> | undefined> {
    let summary = "Couldn't Add Skill";
    let detail = "Something went wrong.";

    const completeSkill = newSkills.value.find(
      (x) => x.name == skillLevelToAdd.skill.name
    );
    if (completeSkill == null) {
      detail = "Invalid skill.";
      toast.addErrorToast(summary, detail);
      return;
    }
    skillLevelToAdd.skill = completeSkill;

    const response = await skillDataAccess.addSkillLevelToMe(skillLevelToAdd);
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        ownedSkills.value.push(response.data.skill);

        summary = "Added Skill";
        detail = "New skill has been saved.";
        toast.addSuccessToast(summary, detail);

        return response;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  function removeSkillLevel(skillLevel: SkillLevel) {
    ownedSkills.value = ownedSkills.value.filter(
      (x) => x.id !== skillLevel.skill.id
    );
  }

  function toggleCreatePopup() {
    showCreateSkillPopup.value = !showCreateSkillPopup.value;
  }

  async function init() {
    await getCategories();
    await getNewSkills();
    loading.value = false;
  }

  async function getCategories() {
    const summary = "Couldn't Get Categories";
    let detail = "Something went wrong.";

    const response = await skillDataAccess.getSkillCategories();
    if (!response) {
      toast.addErrorToast(summary, detail);
    } else {
      if (response.success) {
        categories.value = response.data;
      } else {
        detail = response.message;
        toast.addErrorToast(summary, detail);
      }
    }
  }

  async function getNewSkills() {
    const response = await skillDataAccess.getSkills();
    if (!response || !response.success) {
      return;
    } else {
      allSkills.value = response.data;
    }

    const current = await skillDataAccess.getMySkillLevels();
    if (!current || !current.success) {
      return;
    } else {
      ownedSkills.value = current.data.map((x) => x.skill);
    }
  }

  init();

  return {
    allSkills,
    categories,
    categoryNames,
    ownedSkills,
    loading,
    newSkills,
    newSkillNames,
    showCreateSkillPopup,
    skillLevelToAdd,
    addSkillLevel,
    createSkill,
    removeSkillLevel,
    toggleCreatePopup,
  };
}
